import {
  FaExternalLinkAlt,
  FaGlobe,
  FaFile,
  FaDownload,
  FaEye,
  FaLock,
} from "react-icons/fa";
import { ResourceInterface } from "interface/ResourceInterface";
import { Modal, Button } from "flowbite-react";
import { useAuthContext } from "hooks/useAuthContext";
import { useState, useCallback } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { toast } from "react-hot-toast";

interface ResourceCardProps {
  resource: ResourceInterface;
  setShowPdfPreview: React.Dispatch<React.SetStateAction<boolean>>;
  showPdfPreview: boolean;
}

const ResourceCard = ({
  resource,
  setShowPdfPreview,
  showPdfPreview,
}: ResourceCardProps) => {
  const { profile } = useAuthContext();
  const isSuperAdmin = profile?.access === "admin";
  const visibilityRoles = resource.roleVisibility || [];
  const isPdf = resource.type !== "url";
  const canInteractWithPdf = isPdf && resource.url;
  const [modalResource, setModalResource] = useState<ResourceInterface | null>(
    null
  );
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const handlePreviewClick = useCallback(() => {
    setModalResource(resource);
    setShowPdfPreview(true);
    setLoading(true);
    setError(null);
    setPageNumber(1);
  }, [resource, setShowPdfPreview]);

  const handleCloseModal = useCallback(() => {
    setShowPdfPreview(false);
    setModalResource(null);
    setNumPages(null);
    setPageNumber(1);
    setLoading(false);
    setError(null);
  }, [setShowPdfPreview]);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
      setLoading(false);
    },
    []
  );

  const onDocumentLoadError = useCallback((error: Error) => {
    setError(error);
    setLoading(false);
  }, []);

  const handlePreviousPage = useCallback(() => {
    setPageNumber((prev) => Math.max(prev - 1, 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setPageNumber((prev) => Math.min(prev + 1, numPages || prev));
  }, [numPages]);

  if (!resource) {
    return null;
  }

  return (
    <>
      <div className="group bg-white rounded-lg border border-gray-200 hover:border-[#EB8181] transition-all duration-300 hover:shadow-lg overflow-hidden">
        {/* Resource Type Indicator */}
        <div className="h-1 bg-gradient-to-r from-[#EB8181] to-[#EB8181]/70" />

        <div className="p-5">
          <div className="flex items-start gap-4">
            {/* Icon with Background */}
            <div className="flex-shrink-0">
              <div
                className={`p-3 rounded-lg ${
                  resource.type === "url"
                    ? "bg-[#EB8181]/10 text-[#EB8181]"
                    : canInteractWithPdf
                    ? "bg-[#EB8181]/10 text-[#EB8181]"
                    : "bg-gray-100 text-gray-400"
                } transition-colors`}
              >
                {resource.type === "url" || resource.type === undefined ? (
                  <FaGlobe size={24} />
                ) : (
                  <FaFile size={24} />
                )}
              </div>
            </div>

            {/* Content */}
            <div className="flex-1 min-w-0">
              <div className="flex items-start justify-between gap-4">
                <div>
                  <div className="flex items-center gap-2">
                    <h3 className="font-semibold text-gray-900 group-hover:text-[#EB8181] transition-colors">
                      {resource.title}
                    </h3>
                    {visibilityRoles.length > 0 && (
                      <FaLock className="text-gray-400" size={12} />
                    )}
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <span className="text-xs text-gray-500 uppercase tracking-wider">
                      {resource.type === "url" || resource.type === undefined
                        ? "Web Resource"
                        : "PDF Document"}
                    </span>
                    {isSuperAdmin && visibilityRoles.length > 0 && (
                      <div className="flex items-center gap-1.5">
                        <span className="w-1 h-1 rounded-full bg-gray-300" />
                        <span className="text-xs text-gray-500">
                          Visible to: {visibilityRoles.join(", ")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex items-start gap-2">
                  {resource.type === "url" || resource.type === undefined ? (
                    <a
                      href={resource.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-2 text-gray-400 hover:text-[#EB8181] hover:bg-[#EB8181]/10 rounded-md transition-colors"
                    >
                      <FaExternalLinkAlt size={16} />
                    </a>
                  ) : (
                    canInteractWithPdf && (
                      <>
                        <button
                          onClick={handlePreviewClick}
                          className="p-2 text-gray-400 hover:text-[#EB8181] hover:bg-[#EB8181]/10 rounded-md transition-colors"
                          title="Preview PDF"
                        >
                          <FaEye size={16} />
                        </button>
                        {resource.allowDownload && (
                          <a
                            href={resource.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="p-2 text-gray-400 hover:text-[#EB8181] hover:bg-[#EB8181]/10 rounded-md transition-colors"
                            title="Download PDF"
                          >
                            <FaDownload size={16} />
                          </a>
                        )}
                      </>
                    )
                  )}
                </div>
              </div>

              {/* Description */}
              {resource.description && (
                <p className="text-sm text-gray-600 mt-3 line-clamp-2">
                  {resource.description}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* PDF Preview Modal */}
      {modalResource && (
        <Modal
          show={showPdfPreview}
          onClose={handleCloseModal}
          size="7xl"
          className="pdf-preview-modal"
        >
          <Modal.Header>
            <div className="flex items-center gap-2">
              <span>{modalResource.title}</span>
              {modalResource.roleVisibility?.length > 0 && (
                <FaLock className="text-gray-400" size={12} />
              )}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="relative w-full" style={{ height: "80vh" }}>
              {modalResource.url ? (
                <div className="h-full flex flex-col">
                  {/* PDF Viewer */}
                  <div className="flex-1 overflow-auto flex justify-center bg-gray-100 rounded-lg">
                    {loading && (
                      <div className="flex items-center justify-center h-full">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#EB8181]"></div>
                      </div>
                    )}
                    {error && (
                      <div className="flex items-center justify-center h-full">
                        <div className="text-center">
                          <p className="text-red-500 mb-2">
                            Failed to load PDF
                          </p>
                          <a
                            href={modalResource.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-[#EB8181] hover:underline"
                          >
                            Download instead
                          </a>
                        </div>
                      </div>
                    )}
                    <Document
                      file={modalResource.url}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={onDocumentLoadError}
                      loading={null}
                    >
                      <div
                        onContextMenu={(e) => {
                          if (!modalResource.allowDownload) {
                            e.preventDefault();
                            toast.error(
                              "Downloads are not allowed for this document"
                            );
                          }
                        }}
                        className={`${
                          !modalResource.allowDownload ? "select-none" : ""
                        }`}
                        style={{
                          WebkitTouchCallout: !modalResource.allowDownload
                            ? "none"
                            : "default",
                          userSelect: !modalResource.allowDownload
                            ? "none"
                            : "auto",
                        }}
                      >
                        <Page
                          pageNumber={pageNumber}
                          width={Math.min(window.innerWidth * 0.7, 800)}
                          loading={null}
                          renderAnnotationLayer={false}
                          renderTextLayer={modalResource.allowDownload}
                        />
                      </div>
                    </Document>
                  </div>

                  {/* Page Navigation */}
                  {numPages && numPages > 1 && (
                    <div className="flex items-center justify-center gap-4 pt-4">
                      <button
                        onClick={handlePreviousPage}
                        disabled={pageNumber <= 1}
                        className="px-3 py-1.5 rounded-md text-sm bg-gray-100 text-gray-600 hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Previous
                      </button>
                      <span className="text-sm text-gray-600">
                        Page {pageNumber} of {numPages}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={pageNumber >= (numPages || 1)}
                        className="px-3 py-1.5 rounded-md text-sm bg-gray-100 text-gray-600 hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Next
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-gray-500">PDF file not available</p>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-between w-full">
              <div className="text-sm text-gray-500">
                {modalResource.fileName || "PDF Document"}
                {isSuperAdmin && modalResource.roleVisibility?.length > 0 && (
                  <span className="ml-2">
                    • Visible to: {modalResource.roleVisibility.join(", ")}
                  </span>
                )}
              </div>
              <div className="flex gap-2">
                {modalResource.allowDownload && modalResource.url && (
                  <a
                    href={modalResource.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1.5 px-3 py-1.5 bg-white rounded-md text-sm text-[#EB8181] hover:bg-[#EB8181] hover:text-white transition-all duration-300 border border-[#EB8181]"
                  >
                    <FaDownload size={12} />
                    <span>Download</span>
                  </a>
                )}
                <Button color="gray" onClick={handleCloseModal}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ResourceCard;
