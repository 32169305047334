import { doc, updateDoc, deleteDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import getModelOperations from "utility/model";

// Define the Firestore collection path for FAQs
const FIRESTORE_PATH_FAQ = "FAQ";

// Type for FAQ section and content
export interface FAQContent {
    question: string;
    answer: string;
    order: number; // Add this field to track position
    roles?: string[]; // Array of role IDs that can view this FAQ
}

export type FAQSection = {
    id: string;
    title: string;
    content: FAQContent[];
    timestamp: Date;
    position: number;
    type: "user" | "admin"; // Added type to distinguish between User FAQ and Admin FAQ
};

export const defaultFAQSection: FAQSection = {
    id: "",
    title: "",
    content: [],
    timestamp: new Date(),
    type: "user", // Default type
    position: 0,
};

// --- Helper Functions ---
const ops = getModelOperations(defaultFAQSection);

// Function to add a new FAQ section
async function _addFAQSection(sectionTitle: string, type: "user" | "admin"): Promise<FAQSection> {
    const path = FIRESTORE_PATH_FAQ;

    try {
        // Create a reference to a new document in the FAQSections collection
        const docRef = await firebase.firestore().collection(path).add({
            title: sectionTitle,
            timestamp: new Date(),
            type, // Add type to Firestore document
        });

        const addedSectionSnapshot = await docRef.get();
        const addedSection = { id: addedSectionSnapshot.id, ...addedSectionSnapshot.data() } as FAQSection;

        return addedSection;
    } catch (error) {
        console.error("Error adding FAQ section:", error);
        throw error;
    }
}

// Function to get an FAQ section by ID
function _getFAQSection(sectionId: string): Promise<FAQSection> {
    const path = `${FIRESTORE_PATH_FAQ}/${sectionId}`;
    return ops.getModel(path).then((result) => {
        if (!result) {
            // If no result is found, throw an error or return a default object.
            throw new Error(`FAQ Section with ID ${sectionId} not found.`);
        }
        return result; // result should now be of type FAQSection
    });
}

// Function to get FAQ sections by type
async function _getFAQSectionsByType(type: "user" | "admin"): Promise<FAQSection[]> {
    const path = FIRESTORE_PATH_FAQ;

    const querySnapshot = await firebase.firestore()
        .collection(path)
        .where("type", "==", type)
        .get();

    const sections = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as FAQSection));
    return sections;
}

// Function to get all FAQ sections
async function _getFAQSections(type?: "user" | "admin"): Promise<FAQSection[]> {
    const path = FIRESTORE_PATH_FAQ;

    if (type) {
        // If type is provided, filter the documents by type
        const querySnapshot = await firebase.firestore()
            .collection(path)
            .where("type", "==", type)
            .get();

        const sections = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as FAQSection));
        return sections.sort((a, b) => (a.position || 0) - (b.position || 0));
    } else {
        // If no type is provided, fetch all sections
        const result = await ops.getModels(path);

        // Sort by position before returning
        const sortedResult = result.sort((a, b) => a.position - b.position);
        return sortedResult;
    }
}


// Function to update an FAQ section's title
function _updateFAQSectionTitle(sectionId: string, sectionUpdates: { title: string }): Promise<void> {
    const path = `${FIRESTORE_PATH_FAQ}/${sectionId}`;
    const sectionRef = doc(firebase.firestore(), path);  // Use doc() to get a reference to the specific document
    return updateDoc(sectionRef, sectionUpdates);  // Use updateDoc to update the document
}

// Function to add content (question/answer) to an FAQ section
async function _addContentToSection(sectionId: string, question: string, answer: string, roles: string[]): Promise<void> {
    const section = await _getFAQSection(sectionId);
    const newOrder = section.content.length > 0 ? Math.max(...section.content.map((c) => c.order)) + 1 : 0;

    const path = `${FIRESTORE_PATH_FAQ}/${sectionId}`;
    const sectionRef = doc(firebase.firestore(), path); // Get a reference to the specific section document

    try {
        // Use arrayUnion to add content to an array field without duplication
        await updateDoc(sectionRef, {
            content: arrayUnion({ question, answer, order: newOrder, roles }), // Add the order field
        });
    } catch (error) {
        console.error("Error adding content to section:", error);
        throw error;
    }
}

// Function to delete an FAQ section
async function _deleteFAQSection(sectionId: string): Promise<void> {
    const path = `${FIRESTORE_PATH_FAQ}/${sectionId}`;
    const sectionRef = doc(firebase.firestore(), path); // Get a reference to the document

    try {
        await deleteDoc(sectionRef); // Use deleteDoc to delete the document
    } catch (error) {
        console.error("Error deleting FAQ section:", error);
        throw error;
    }
}

// Function to delete a question/answer from FAQ section
export const deleteContentFromFAQSection = async (sectionId: string, content: FAQContent) => {
    const sectionRef = firebase.firestore().doc(`${FIRESTORE_PATH_FAQ}/${sectionId}`);
    
    try {
        await sectionRef.update({
            content: firebase.firestore.FieldValue.arrayRemove(content)
        });
    } catch (error) {
        console.error("Error deleting content:", error);
        throw error;
    }
};

// Function to edit question/answer in FAQ section
async function _editContentInFAQSection(sectionId: string, oldContent: FAQContent, newContent: FAQContent): Promise<void> {
    const path = `${FIRESTORE_PATH_FAQ}/${sectionId}`;
    const sectionRef = doc(firebase.firestore(), path);

    try {
        // Remove old content and add updated content
        await updateDoc(sectionRef, {
            content: arrayRemove(oldContent),
        });

        // Add the updated content back to the section
        await updateDoc(sectionRef, {
            content: arrayUnion(newContent),
        });
    } catch (error) {
        console.error("Error editing content in FAQ section:", error);
        throw error;
    }
}

// Function to move content up or down
async function _moveContent(sectionId: string, index: number, direction: "up" | "down"): Promise<void> {
    const section = await _getFAQSection(sectionId);
    const content = [...section.content];

    if (
        (direction === "up" && index === 0) ||
        (direction === "down" && index === content.length - 1)
    ) {
        return; // Can't move further
    }

    const swapIndex = direction === "up" ? index - 1 : index + 1;
    [content[index].order, content[swapIndex].order] = [content[swapIndex].order, content[index].order];

    // Sort content by order and update in Firestore
    content.sort((a, b) => a.order - b.order);
    await _updateFAQContent(sectionId, content);
}

// Function to update FAQ content after reordering
async function _updateFAQContent(sectionId: string, updatedContent: FAQContent[]): Promise<void> {
    const path = `${FIRESTORE_PATH_FAQ}/${sectionId}`;
    const sectionRef = doc(firebase.firestore(), path);

    try {
        // Ensure content is sorted by `order`
        updatedContent.sort((a, b) => a.order - b.order);
        await updateDoc(sectionRef, { content: updatedContent });
    } catch (error) {
        console.error("Error updating FAQ content:", error);
        throw error;
    }
}

// --- Public Functions ---
export async function addFAQSection(sectionTitle: string, type: "user" | "admin"): Promise<FAQSection> {
    return _addFAQSection(sectionTitle, type);
}

export const getFAQSectionById = _getFAQSection;

export async function getUserFAQSections(): Promise<FAQSection[]> {
    return _getFAQSectionsByType("user");
}

export async function getAdminFAQSections(): Promise<FAQSection[]> {
    return _getFAQSectionsByType("admin");
}

// Public function to export
export async function getFAQSections(type?: "user" | "admin"): Promise<FAQSection[]> {
    return _getFAQSections(type);
}

export async function updateFAQSectionTitle(sectionId: string, newTitle: string): Promise<void> {
    return _updateFAQSectionTitle(sectionId, { title: newTitle });
}

export async function addContentToFAQSection(sectionId: string, question: string, answer: string, roles: string[]): Promise<void> {
    return _addContentToSection(sectionId, question, answer, roles);
}

export async function deleteFAQSection(sectionId: string): Promise<void> {
    return _deleteFAQSection(sectionId);
}

export async function editContentInFAQSection(sectionId: string, oldContent: FAQContent, newContent: FAQContent): Promise<void> {
    return _editContentInFAQSection(sectionId, oldContent, newContent);
}

async function _updateFAQSectionPositions(updatedSections: { id: string; position: number }[]): Promise<void> {
    const batch = firebase.firestore().batch(); // Initialize Firestore batch using compat syntax

    try {
        updatedSections.forEach((section) => {
            const path = `${FIRESTORE_PATH_FAQ}/${section.id}`;
            const sectionRef = firebase.firestore().doc(path); // Get document reference using compat syntax
            batch.update(sectionRef, { position: section.position }); // Update the position field
        });

        // Commit the batch operation
        await batch.commit();
    } catch (error) {
        console.error("Error updating FAQ section positions:", error);
        throw error;
    }
}

export async function moveContent(sectionId: string, index: number, direction: "up" | "down"): Promise<void> {
    return _moveContent(sectionId, index, direction);
}

export async function updateFAQSectionPositions(updatedSections: { id: string; position: number }[]): Promise<void> {
    return _updateFAQSectionPositions(updatedSections);
}