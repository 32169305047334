import { QueryConstraint, UpdateData } from "firebase/firestore";
import firebase from "firebase/compat/app";
import getModelOperations from "utility/model";

const FIRESTORE_PATH_APP_FEEDBACK = "AppFeedback";
export type FEEDBACK_RATING = 1 | 2 | 3 | 4 | 5 | "No Rating";

/**
 * Feedback data model for user submissions.
 * id: Unique identifier for each feedback (assigned by Firestore)
 * feedback: General feedback text from the user
 * suggestions: Suggestions for improvements
 * rating: User's rating of the app experience (0-5)
 * problem: Description of issues encountered
 * files: Array of file names for uploaded screenshots or videos
 * device: Device type (e.g., "Mobile Phone", "Tablet", etc.)
 * deviceModel: Specific model of the user's device
 * os: Operating System of the device (e.g., "Android", "iOS", etc.)
 * browser: Web browser used (e.g., "Google Chrome", "Safari", etc.)
 * otherBrowser: Additional browser info if applicable
 * timestamp: Date when the feedback was submitted
 */
export interface Feedback {
  organisation: string;
  feedback: string;
  suggestions: string;
  rating: FEEDBACK_RATING;
  problem: string;
  files: string[]; // File names or URLs
  device: string;
  deviceModel: string;
  os: string;
  browser: string;
  otherBrowser: string;
  timestamp: Date;
}

export const defaultFeedback: Feedback = {
  feedback: "",
  suggestions: "",
  organisation: "",
  rating: "No Rating",
  problem: "",
  files: [],
  device: "",
  deviceModel: "",
  os: "",
  browser: "",
  otherBrowser: "",
  timestamp: new Date(),
};

// --- Helper Functions ---

const ops = getModelOperations(defaultFeedback);

async function _addFeedback(feedback: Feedback) {
  const path = FIRESTORE_PATH_APP_FEEDBACK;

  try {
    const docRef = await firebase.firestore().collection(path).add(feedback);

    const addedFeedbackSnapshot = await docRef.get();
    const addedFeedback = {
      id: addedFeedbackSnapshot.id,
      ...addedFeedbackSnapshot.data(),
    } as Feedback & { id: string };

    return addedFeedback;
  } catch (error) {
    console.error("Error adding feedback:", error);
    throw error;
  }
}

function _getFeedback(feedbackId: string) {
  const path = `${FIRESTORE_PATH_APP_FEEDBACK}/${feedbackId}`;
  return ops.getModel(path);
}

async function _getFeedbacks(...queryConstraints: QueryConstraint[]) {
  const path = FIRESTORE_PATH_APP_FEEDBACK;
  const result = await ops.getModels(path, ...queryConstraints);
  return result;
}

function _updateFeedback(
  feedbackId: string,
  feedbackUpdates: UpdateData<Feedback>
) {
  const path = `${FIRESTORE_PATH_APP_FEEDBACK}/${feedbackId}`;
  return ops.updateModel(path, feedbackUpdates);
}

export async function addFeedback(feedbackData: Feedback) {
  return _addFeedback(feedbackData);
}

export const getFeedbackById = _getFeedback;

export const getFeedbacks = _getFeedbacks;

export async function updateFeedbackRating(
  feedbackId: string,
  rating: FEEDBACK_RATING
) {
  return _updateFeedback(feedbackId, { rating });
}

export async function deleteFeedback(feedbackId: string) {
  const path = `${FIRESTORE_PATH_APP_FEEDBACK}/${feedbackId}`;

  try {
    await firebase.firestore().doc(path).delete();
    console.log(`Feedback with ID ${feedbackId} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting feedback:", error);
    throw error;
  }
}
