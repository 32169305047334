import { useTour } from "@reactour/tour";
import EmptySpace from "components/utility/EmptySpace";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { ResourceInterface } from "interface/ResourceInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useState, useMemo, lazy, Suspense } from "react";
import { toast } from "react-hot-toast";

import { FaToggleOn } from "react-icons/fa";


import { useAuthContext } from "hooks/useAuthContext";
import { OrganisationRoleContextProvider } from "context/OrganisationRoleContext";
 
const ResourceForm = lazy(() => import("./ResourceForm"));
const InstructionsPanel = lazy(() => import("./InstructionsPanel"));
const ResourcesList = lazy(() => import("./ResourcesList"));
 
interface ResourceSettingsProps {
  resources: ResourceInterface[];
}

type OrganisationUpdateKey = 'useDefaultResources';

const ResourcesSettings = ({ resources }: ResourceSettingsProps) => {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation, updateSelectedOrganisation, organisations } = useOrganisationContext();
  const { profile, user } = useAuthContext();
  const [isUpdating, setIsUpdating] = useState(false);

  const hasPermission = useMemo(() => {
    // If user is super admin, they can see everything
    if (profile?.access === "admin") {
      return true;
    }

    // If no user/profile, they can't see role-restricted content
    if (!profile || !user) {
      return false;
    }

    // Get all user roles across all organizations
    const userRoles = organisations.reduce<string[]>((roles, org) => {
      const userRole = org.users?.[user.uid];
      return userRole ? [...roles, userRole] : roles;
    }, []);

    // Check if user has admin or manager role
    return userRoles.some(role => ["admin", "manager"].includes(role));
  }, [profile, user, organisations]);

  const handleCheckboxChange = async (checkboxName: OrganisationUpdateKey) => {
    if (isUpdating || !selectedOrganisation) return;

    setIsUpdating(true);
    const newValue = !selectedOrganisation[checkboxName];

    try {
      // Create a strongly typed update object
      const updateData = {
        [checkboxName]: newValue
      } as Record<OrganisationUpdateKey, boolean>;

      await updateSelectedOrganisation(updateData);
      toast.success("Settings updated successfully. Please refresh the page to see the changes." , {duration : 5000});
    } catch (error) {
      toast.error("Failed to update settings. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  if (!hasPermission) {
    return (
      <div className="text-center py-8 text-gray-500">
        You don't have permission to access this section.
      </div>
    );
  }

  return (
    <>
      <EmptySpace />
      <button
        className={`${classes["button"]}`}
        onClick={() => setIsTourOpen(true)}
      >
        Show Tutorial
      </button>
      <EmptySpace />
      <div className="flex flex-col gap-4">
      <div className="mb-2">
        <p className="text-lg my-2">
          Resources for{" "}
          <strong>{selectedOrganisation && selectedOrganisation.name}</strong>
        </p>
      </div>

        {/* Instructions Panel */}
        <Suspense fallback={<div>Loading...</div>}>
          <InstructionsPanel />
        </Suspense>

        {/* Default Resources Section */}
        <div className="bg-default rounded-lg border border-gray-200 max-w-md">
          <div className="flex items-center gap-2 p-6">
            <div className="w-2 h-2 rounded-full bg-[#EB8181]" />
            <h2 className="text-lg font-semibold text-gray-800">
              Default Resources
            </h2>
          </div>

          {selectedOrganisation && (
            <div className="px-6 pb-6 space-y-4">
              <label className="flex items-start gap-2 cursor-pointer">
                <div className="flex-shrink-0 pt-1">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedOrganisation?.useDefaultResources ?? true}
                      onChange={() => handleCheckboxChange("useDefaultResources")}
                      disabled={isUpdating}
                      className="sr-only peer"
                    />
                    <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#EB8181] ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`}></div>
                  </label>
                </div>
                <div className="space-y-2">
                  <span className="block text-gray-700 font-medium">
                    Use default resources links
                  </span>
                  <p className="text-sm text-gray-500">
                    When enabled, users will see a curated list of default
                    mental health resources including links to IMH CHAT,
                    Mindline.sg, HealthHub SG, and other helpful services.
                    {selectedOrganisation?.useDefaultResources
                      ? " These resources are currently visible to users."
                      : " These resources are currently hidden from users."}
                  </p>
                </div>
              </label>

              <div className="mt-4 bg-gray-50 p-4 rounded-md">
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <FaToggleOn className="text-gray-400" />
                  <span>
                    {selectedOrganisation?.useDefaultResources
                      ? "Default resources are enabled"
                      : "Default resources are disabled"}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`${classes["container"]} mb-[100px] space-y-8 mt-4`}>
        

        {/* Main Content Area */}
        <div className="flex w-full gap-2">
          {/* Left Side - Form */}
          <div className="flex-2 max-w-sm">
             <Suspense fallback={<div>Loading...</div>}>
               {selectedOrganisation && 
               <OrganisationRoleContextProvider organisationId={selectedOrganisation?.id}>
                 <ResourceForm />
               </OrganisationRoleContextProvider>
               }
             </Suspense>
          </div>

          {/* Right Side - Resources List */}
          <div className="flex-1">
            <Suspense fallback={<div>Loading...</div>}>
              <ResourcesList resources={resources} />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourcesSettings;
 
