import React, { useEffect, useState, useCallback, Suspense, lazy } from "react";
import NavAdmin from "components/Admin/NavAdmin";
import SidebarAdmin from "components/Admin/SidebarAdmin";

import classes from "pages/AllCircles/Admin/Admin.module.css";
import { OrganisationContextProvider } from "context/OrganisationContext";
import { getFeedbacks, deleteFeedback } from "models/feedback";
import { Timestamp } from "firebase/firestore";
import FeedbackCard from "./components/FeedbackList/FeedbackCard";

import { Feedback, ChartData, FilterState } from "./types";
import ViewDetailsModal from "./components/Modals/ViewDetailsModal";
import DeleteModal from "./components/Modals/DeleteModal";

const FilterIndicator = lazy(
  () => import("./components/ChartSection/FilterIndicator")
);

const FeedbackChart = lazy(
  () => import("./components/ChartSection/FeedbackChart")
);
const PaginateFeedbackList = lazy(
  () => import("./components/Pagination/PaginateFeedbackList")
);
const FilterSection = lazy(
  () => import("./components/FilterSection/FilterSection")
);

const FeedbackData: React.FC = () => {
  const [feedbackList, setFeedbackList] = useState<Feedback[]>([]);
  const [filteredFeedbackList, setFilteredFeedbackList] = useState<Feedback[]>(
    []
  );
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(
    null
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState<Feedback | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [chartData, setChartData] = useState<ChartData | null>(null);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;

  const paginatedFeedbackList = filteredFeedbackList.slice(
    (currentPage - 1) * itemsPerPage, // Calculate the starting index for current page
    currentPage * itemsPerPage // Calculate the ending index for current page
  );

  // Define options for dropdown filters
  const organisationOptions = Array.from(
    new Set(feedbackList.map((feedback) => feedback.organisation || ""))
  );

  const osOptions = ["Android", "iOS", "Windows", "Linux", "MacOS"];
  const deviceOptions = [
    "Mobile Phone",
    "Tablet",
    "Laptop Computer",
    "Desktop Computer",
  ];
  const browserOptions = [
    "Google Chrome",
    "Safari",
    "Mozilla Firefox",
    "Microsoft Edge",
    "Other",
  ];
  const ratingOptions = ["1", "2", "3", "4", "5", "No Rating"];
  const formSectionOptions = ["Feedback", "Problem", "Device"];

  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Filter states
  const [osFilter, setOsFilter] = useState<string[]>(["All", ...osOptions]);
  const [deviceFilter, setDeviceFilter] = useState<string[]>([
    "All",
    ...deviceOptions,
  ]);
  const [browserFilter, setBrowserFilter] = useState<string[]>([
    "All",
    ...browserOptions,
  ]);
  const [ratingFilter, setRatingFilter] = useState<string[]>([
    "All",
    ...ratingOptions,
  ]);
  const [dateFilter, setDateFilter] = useState<string[]>(["All"]);
  const [formSectionFilter, setFormSectionFilter] = useState<string[]>([
    "All",
    ...formSectionOptions,
  ]);
  const [monthFilter, setMonthFilter] = useState<string[]>([]);
  const [filterType, setFilterType] = useState("average");
  const [organisationFilter, setOrganisationFilter] = useState<string[]>([
    "All",
    ...organisationOptions,
  ]);
  const [yearFilter, setYearFilter] = useState<string[]>(["All"]);

  // Add this before handleCheckboxChange
  const filters = {
    organisation: organisationFilter,
    os: osFilter,
    device: deviceFilter,
    browser: browserFilter,
    rating: ratingFilter,
    date: dateFilter,
    month: monthFilter,
    formSection: formSectionFilter,
    year: yearFilter,
  };

  const calculateChartData = useCallback(
    (feedbacks: Feedback[], filterType: string) => {
      const isMonthBased =
        monthFilter.includes("All") ||
        (monthFilter.length > 0 && !monthFilter.includes("All"));

      const ratingsByTime: Record<string, number[]> = {};
      const responsesByTime: Record<string, number> = {};

      // Filter by rating first
      const filteredFeedbacks = feedbacks.filter((feedback) => {
        if (ratingFilter.includes("All")) return true; // Include all ratings
        if (
          feedback.rating === "No Rating" &&
          ratingFilter.includes("No Rating")
        )
          return true;
        return ratingFilter.includes(feedback.rating.toString());
      });

      filteredFeedbacks.forEach((feedback) => {
        const date = new Date(feedback.timestamp);
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const timeKey = isMonthBased ? `${month}-${year}` : year;

        // Count all responses
        if (!responsesByTime[timeKey]) responsesByTime[timeKey] = 0;
        responsesByTime[timeKey] += 1;

        // Only count numeric ratings for average calculation
        if (feedback.rating !== "No Rating") {
          const numericRating = parseInt(feedback.rating.toString(), 10);
          if (!ratingsByTime[timeKey]) ratingsByTime[timeKey] = [];
          ratingsByTime[timeKey].push(numericRating);
        }
      });

      if (filteredFeedbacks.length === 0) {
        setChartData({
          labels: [],
          datasets: [
            {
              label: "Average Rating",
              data: [],
              hidden: filterType !== "average",
            },
            {
              label: "Number of Responses",
              data: [],
              hidden: filterType !== "responses",
            },
          ],
        });
        return;
      }

      const chartData = Object.keys(responsesByTime).map((timeKey) => {
        const ratings = ratingsByTime[timeKey] || [];
        const average =
          ratings.length > 0
            ? ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length
            : 0;

        return {
          timeKey,
          average,
          responseCount: responsesByTime[timeKey],
        };
      });

      const labels = Array.from(
        new Set(chartData.map((item) => item.timeKey))
      ).sort();

      const ratingData = labels.map((label) => {
        const dataPoint = chartData.find((item) => item.timeKey === label);
        return dataPoint ? dataPoint.average : 0;
      });

      const responseData = labels.map((label) => {
        const dataPoint = chartData.find((item) => item.timeKey === label);
        return dataPoint ? dataPoint.responseCount : 0;
      });

      setChartData({
        labels,
        datasets: [
          {
            label: "Average Rating",
            data: ratingData,
            hidden: filterType !== "average",
          },
          {
            label: "Number of Responses",
            data: responseData,
            hidden: filterType !== "responses",
          },
        ],
      });
    },
    [monthFilter, ratingFilter]
  );

  useEffect(() => {
    if (
      organisationFilter.includes("All") &&
      organisationFilter.length !== organisationOptions.length + 1
    ) {
      setOrganisationFilter(["All", ...organisationOptions]);
    }
  }, [organisationOptions, organisationFilter]);

  const handleCheckboxChange = (
    filterName: keyof FilterState,
    value: string,
    allOptions: string[]
  ) => {
    const setterMap = {
      organisation: setOrganisationFilter,
      os: setOsFilter,
      device: setDeviceFilter,
      browser: setBrowserFilter,
      rating: setRatingFilter,
      date: setDateFilter,
      month: setMonthFilter,
      formSection: setFormSectionFilter,
      year: setYearFilter,
    };

    const setter = setterMap[filterName];
    if (setter) {
      const currentFilter = filters[filterName];

      if (value === "All") {
        // If "All" is selected, toggle between all options and none
        if (currentFilter.includes("All")) {
          setter([]); // Deselect everything
        } else {
          setter(["All", ...allOptions.filter((opt) => opt !== "All")]); // Select all
        }
      } else {
        let newFilter: string[];

        if (currentFilter.includes("All")) {
          // If "All" was selected, remove it and add only the clicked option
          newFilter = [value];
        } else if (currentFilter.includes(value)) {
          // If option was already selected, remove it
          newFilter = currentFilter.filter((item) => item !== value);
        } else {
          // Add the new option
          newFilter = [...currentFilter, value];
          // If all individual options are selected, switch to "All"
          if (newFilter.length === allOptions.length - 1) {
            // -1 for "All" option
            newFilter = ["All", ...allOptions.filter((opt) => opt !== "All")];
          }
        }

        setter(newFilter.length ? newFilter : ["All"]);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFeedbacks();
        const formattedData = data
          .map((feedback) => ({
            ...feedback,
            id: feedback.id,
            timestamp:
              feedback.timestamp instanceof Timestamp
                ? feedback.timestamp.toDate()
                : new Date(feedback.timestamp),
          }))
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );

        setFeedbackList(formattedData);
        setFilteredFeedbackList(formattedData);

        const years = [
          "All",
          ...new Set(
            formattedData.map((f) =>
              new Date(f.timestamp).getFullYear().toString()
            )
          ),
        ];
        setDateFilter(years);

        calculateChartData(formattedData, filterType); // Call the memoized function
      } catch (error) {
        console.error("Error fetching feedback data:", error);
        setFeedbackList([]);
      }
    };

    fetchData();
  }, [calculateChartData, filterType]); // Include all dependencies

  const matchSectionFilter = useCallback(
    (feedback: Feedback) => {
      const conditions = {
        Feedback: feedback.feedback && feedback.suggestions && feedback.rating,
        Problem: feedback.problem && feedback.files.length > 0,
        Device: feedback.device && feedback.os && feedback.browser,
        All: true,
      };

      return (
        formSectionFilter.length === 0 ||
        formSectionFilter.includes("All") ||
        formSectionFilter.some((filter) => conditions[filter])
      );
    },
    [formSectionFilter]
  );

  useEffect(() => {
    const isAllDeselected =
      organisationFilter.length === 0 ||
      (organisationFilter.length === 1 && organisationFilter[0] === "All") ||
      (!osFilter.includes("All") && osFilter.length === 0) ||
      (!deviceFilter.includes("All") && deviceFilter.length === 0) ||
      (!browserFilter.includes("All") && browserFilter.length === 0) ||
      (!ratingFilter.includes("All") && ratingFilter.length === 0) ||
      (!dateFilter.includes("All") && dateFilter.length === 0) ||
      (!formSectionFilter.includes("All") && formSectionFilter.length === 0);

    if (isAllDeselected) {
      setFilteredFeedbackList([]); // No data to display
      calculateChartData([], filterType); // Update chart data with empty array
      setCurrentPage(1); // Reset to the first page
      return;
    }

    const filteredList = feedbackList.filter((feedback) => {
      const feedbackDate = new Date(feedback.timestamp);

      const monthNamesToIndex: { [key: string]: number } = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11,
      };

      // Add year filter
      const feedbackYear = new Date(feedback.timestamp)
        .getFullYear()
        .toString();
      const yearMatch =
        yearFilter.includes("All") || yearFilter.includes(feedbackYear);

      return (
        (organisationFilter.length > 0
          ? organisationFilter.includes("All") ||
            (feedback.organisation &&
              organisationFilter.includes(feedback.organisation))
          : true) &&
        (osFilter.length > 0
          ? osFilter.includes(feedback.os || "") || osFilter.includes("All")
          : true) &&
        (deviceFilter.length > 0
          ? deviceFilter.includes(feedback.device || "") ||
            deviceFilter.includes("All")
          : true) &&
        (browserFilter.length > 0
          ? browserFilter.includes(feedback.browser || "") ||
            browserFilter.includes("All")
          : true) &&
        (ratingFilter.length > 0
          ? ratingFilter.includes("All") ||
            ratingFilter.includes(feedback.rating.toString()) ||
            (feedback.rating === "No Rating" &&
              ratingFilter.includes("No Rating"))
          : true) &&
        (dateFilter.length > 0
          ? dateFilter.includes("All") ||
            dateFilter.includes(feedbackDate.getFullYear().toString())
          : true) &&
        (monthFilter.length > 0
          ? monthFilter.includes("All") ||
            monthFilter.some(
              (month) => feedbackDate.getMonth() === monthNamesToIndex[month]
            )
          : true) &&
        matchSectionFilter(feedback) &&
        yearMatch
      );
    });

    setFilteredFeedbackList(filteredList);
    calculateChartData(filteredList, filterType); // Recalculate chart data with filterType
    setCurrentPage(1); // Reset to first page after filtering
  }, [
    organisationFilter,
    osFilter,
    deviceFilter,
    browserFilter,
    ratingFilter,
    dateFilter,
    monthFilter,
    formSectionFilter,
    feedbackList,
    matchSectionFilter,
    calculateChartData,
    filterType,
    yearFilter,
  ]);

  const getColourForYear = (label: string): string => {
    const year = label.split("-")[label.split("-").length - 1];
    switch (year) {
      case "2023":
        return "rgba(235, 129, 129, 0.7)"; // Theme pink (#EB8181)
      case "2024":
        return "rgba(255, 178, 107, 0.7)"; // Warm orange
      case "2025":
        return "rgba(129, 199, 132, 0.7)"; // Soft green
      case "2026":
        return "rgba(121, 134, 203, 0.7)"; // Muted purple
      default:
        return "rgba(158, 158, 158, 0.7)"; // Neutral gray
    }
  };

  useEffect(() => {
    const isMonthBased =
      monthFilter.includes("All") ||
      (monthFilter.length > 0 && !monthFilter.includes("All"));
    const adjustedFilterType = isMonthBased
      ? `${filterType}-month`
      : filterType;

    calculateChartData(filteredFeedbackList, adjustedFilterType);
  }, [monthFilter, filterType, filteredFeedbackList, calculateChartData]);

  const confirmDelete = (feedback: Feedback) => {
    setFeedbackToDelete(feedback);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (feedbackToDelete) {
      setIsDeleting(true);
      try {
        await deleteFeedback(feedbackToDelete.id);
        setFeedbackList(
          feedbackList.filter((feedback) => feedback.id !== feedbackToDelete.id)
        );
        setShowDeleteModal(false);
        setFeedbackToDelete(null);
      } catch (error) {
        console.error("Error deleting feedback:", error);
      } finally {
        setIsDeleting(false); // Reset deletion state
      }
    }
  };

  // Add before the FilterSection component
  const yearOptions = Array.from(
    new Set(
      feedbackList.map((f) => new Date(f.timestamp).getFullYear().toString())
    )
  ).sort();

  const options = {
    osOptions,
    deviceOptions,
    browserOptions,
    ratingOptions,
    monthOptions,
    formSectionOptions,
    organisationOptions,
    yearOptions,
  };

  const filterLabels = {
    organisation: "Organisation",
    os: "OS",
    device: "Device",
    browser: "Browser",
    rating: "Rating",
    month: "Month",
    year: "Year",
    formSection: "Form Section",
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <NavAdmin />
      <div className={`${classes.container} bg-default`}>
        <SidebarAdmin />
        <div className={`${classes.main} p-6 `}>
          <div className="w-full flex justify-center">
            <div className="mb-8">
              <h1 className="text-2xl font-semibold text-gray-800">
                Feedback Data
              </h1>
              <p className="text-sm text-gray-600 mt-1">
                Manage and analyze feedback submissions
              </p>
            </div>
          </div>

          <OrganisationContextProvider>
            <div className="bg-white rounded-lg shadow-sm p-8 mb-8  mx-auto">
              {/* Filter and Chart Section */}
              <div className="grid grid-cols-1 lg:grid-cols-5 gap-12">
                {/* Filters */}
                <div className="lg:col-span-1">
                  <h2 className="text-lg font-medium text-gray-700 mb-6">
                    Filters
                  </h2>
                  <div className="space-y-4">
                    <Suspense fallback={"Loading..."}>
                      <FilterSection
                        filters={{
                          osFilter: filters.os,
                          deviceFilter: filters.device,
                          browserFilter: filters.browser,
                          ratingFilter: filters.rating,
                          dateFilter: filters.date,
                          monthFilter: filters.month,
                          formSectionFilter: filters.formSection,
                          organisationFilter: filters.organisation,
                          yearFilter: filters.year,
                        }}
                        options={options}
                        onFilterChange={handleCheckboxChange}
                      />
                    </Suspense>
                  </div>
                </div>

                {/* Chart */}
                <div className="lg:col-span-4">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-lg font-medium text-gray-700">
                      Analytics
                    </h2>
                    <select
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                      className="px-4 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-[#EB8181] focus:border-transparent"
                    >
                      <option value="average">Average Rating</option>
                      <option value="responses">Number of Responses</option>
                    </select>
                  </div>

                  <Suspense fallback="Loading...">
                    <FeedbackChart
                      chartData={chartData}
                      filterType={filterType}
                      getColourForYear={getColourForYear}
                    />
                  </Suspense>

                  <Suspense fallback="Loading...">
                    <div className="mt-6 pt-4 border-t">
                      <FilterIndicator
                        filters={filters}
                        filterLabels={filterLabels}
                      />
                    </div>
                  </Suspense>
                </div>
              </div>
            </div>

            {/* Feedback List Section */}
            <div className="bg-white rounded-lg shadow-sm p-8 mx-auto">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg font-medium text-gray-700">
                  Feedback Submissions
                </h2>
                <div className="text-sm text-gray-600">
                  Total: {feedbackList.length} Feedback
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {paginatedFeedbackList.length > 0 ? (
                  paginatedFeedbackList.map((feedback) => (
                    <FeedbackCard
                      key={feedback.id}
                      feedback={feedback}
                      onView={() => setSelectedFeedback(feedback)}
                      onDelete={() => confirmDelete(feedback)}
                    />
                  ))
                ) : (
                  <div className="col-span-full text-center py-8 text-gray-500">
                    No feedback found with the selected filters.
                  </div>
                )}
              </div>

              {/* Pagination */}
              <Suspense fallback="Loading...">
                <PaginateFeedbackList
                  paginatedFeedbackList={paginatedFeedbackList}
                  filteredFeedbackList={filteredFeedbackList}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                />
              </Suspense>
            </div>
          </OrganisationContextProvider>
        </div>
      </div>

      {/* Modals */}
      {selectedFeedback && (
        <ViewDetailsModal
          feedback={selectedFeedback}
          onClose={() => setSelectedFeedback(null)}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          isDeleting={isDeleting}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default FeedbackData;
