import { useState, useEffect, Suspense, lazy } from "react";
import { FiPlus, FiHelpCircle } from "react-icons/fi";
import {
  addFAQSection,
  getFAQSections,
  addContentToFAQSection,
  deleteFAQSection,
  deleteContentFromFAQSection,
  editContentInFAQSection,
  updateFAQSectionTitle,
  updateFAQSectionPositions,
  FAQSection,
  FAQContent
} from "models/ManageFAQ";
import NavAdmin from "components/Admin/NavAdmin";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import styles from "./ManageFAQ.module.css";
import { DragEndEvent } from "@dnd-kit/core";

import toast, { Toaster } from "react-hot-toast";
const AddContentForm = lazy(() => import("components/FAQ/AddContentForm"));
const ConfirmationModal = lazy(
  () => import("components/FAQ/ConfirmationModal")
);
const FAQSectionContent = lazy(
  () => import("components/FAQ/FAQSectionContent")
);
const FAQSectionHeader = lazy(() => import("components/FAQ/FAQSectionHeader"));
const FAQInstructionsPanel = lazy(() => import("components/FAQ/FAQInstructionsPanel"));

/**
 * ManageFAQ Component
 * Main component for managing FAQ sections and content
 * Handles CRUD operations for FAQ sections and their content
 */

/**
 * State Interfaces
 */
// Remove or comment out this local interface since we'll import it
// type FAQContent = {
//   question: string;
//   answer: string;
//   order: number;
// };

const ManageFAQ = () => {
  const [sections, setSections] = useState<FAQSection[]>([]);
  const [newSectionTitle, setNewSectionTitle] = useState<string>("");
  const [newContent, setNewContent] = useState<Record<string, FAQContent>>({});
  const [editingContent, setEditingContent] = useState<
    Record<string, { index: number; content: FAQContent } | null>
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const [editingSectionTitle, setEditingSectionTitle] = useState<
    Record<string, string | null>
  >({});

  // Modal state
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    message: string;
    onConfirm: () => void;
  }>({
    isOpen: false,
    message: "",
    onConfirm: () => {},
  });

  //Dropdown to switch between Admin FAQ and user FAQ
  const [faqType, setFaqType] = useState<"admin" | "user">("admin");

  // Add this to your state declarations at the top of the component
  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});

  // New state for instructions panel
  const [showInstructions, setShowInstructions] = useState<boolean>(false);

  /**
   * Opens confirmation modal with custom message and action
   * @param message - Text to display in modal
   * @param action - Callback function to execute on confirm
   */
  const openConfirmationModal = (message: string, action: () => void) => {
    setModalState({
      isOpen: true,
      message,
      onConfirm: action,
    });
  };

  /**
   * Moves a section up in the order
   * Updates both UI and backend
   * @param index - Current position of the section
   */
  const moveSectionUp = async (index: number) => {
    if (index === 0) return; // If it's the first section, don't move up
    const loadingToast = toast.loading("Saving new order...");
    const newSections = [...sections];
    const [movedSection] = newSections.splice(index, 1); // Remove section at index
    newSections.splice(index - 1, 0, movedSection); // Insert it before the previous section

    try {
      const updatedSections = newSections.map((section, newIndex) => ({
        id: section.id,
        position: newIndex,
      }));
      await updateFAQSectionPositions(updatedSections); // Save new positions to Firestore
      setSections(newSections);
      toast.success("Order updated successfully", { id: loadingToast });
    } catch (error) {
      console.error("Error updating section positions:", error);
    }
  };

  /**
   * Moves a section down in the order
   * Updates both UI and backend
   * @param index - Current position of the section
   */
  const moveSectionDown = async (index: number) => {
    if (index === sections.length - 1) return; // If it's the last section, don't move down
    const loadingToast = toast.loading("Saving new order...");
    const newSections = [...sections];
    const [movedSection] = newSections.splice(index, 1); // Remove section at index
    newSections.splice(index + 1, 0, movedSection); // Insert it after the next section

    try {
      const updatedSections = newSections.map((section, newIndex) => ({
        id: section.id,
        position: newIndex,
      }));
      await updateFAQSectionPositions(updatedSections); // Save new positions to Firestore
      setSections(newSections);
      toast.success("Order updated successfully", { id: loadingToast });
    } catch (error) {
      console.error("Error updating section positions:", error);
    }
  };

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const fetchedSections = await getFAQSections(faqType);
        setSections(fetchedSections);
      } catch (error) {
        console.error("Error fetching FAQ sections:", error);
        setSections([]);
      }
    };
    fetchSections();
  }, [faqType]);

  /**
   * Adds a new FAQ section
   * Validates input and updates both UI and backend
   */
  const handleAddSection = async () => {
    if (!newSectionTitle.trim()) return;

    setLoading(true);
    try {
      const newSection = await addFAQSection(newSectionTitle, faqType);
      setSections((prevSections) => [...prevSections, newSection]);
      setNewSectionTitle("");
      setEditingSectionTitle((prev) => ({
        ...prev,
        [newSection.id]: null, // Ensure the new section is not in editing state
      }));
    } catch (error) {
      console.error("Error adding section:", error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Adjusts textarea height based on content
   * @param textarea - Textarea element to adjust
   */
  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  /**
   * Handles changes to new content form
   * @param sectionId - ID of section for new content
   * @param field - Field being changed (question/answer)
   * @param value - New value for the field
   */
  const handleContentChange = (
    sectionId: string,
    field: keyof FAQContent,
    value: string | string[]
  ) => {
    setNewContent((prev) => ({
      ...prev,
      [sectionId]: {
        ...prev[sectionId],
        [field]: value,
        order: prev[sectionId]?.order || 0
      } as FAQContent
    }));
  };

  /**
   * Adds new content to a section
   * @param sectionId - ID of section to add content to
   */
  const handleAddContent = async (sectionId: string) => {
    const content = newContent[sectionId];
    if (!content || !content.question.trim() || !content.answer.trim()) {
      return;
    }

    setLoading(true);
    try {
      const section = sections.find((s) => s.id === sectionId);
      if (!section) return;

      const currentContent = section.content || [];
      const newOrder = currentContent.length;

      const contentWithOrder = {
        ...content,
        order: newOrder,
        roles: content.roles || []
      };

      await addContentToFAQSection(
        sectionId,
        contentWithOrder.question,
        contentWithOrder.answer,
        contentWithOrder.roles
      );

      setSections((prevSections) =>
        prevSections.map((section) =>
          section.id === sectionId
            ? {
                ...section,
                content: [...(section.content || []), contentWithOrder],
              }
            : section
        )
      );

      setNewContent((prev) => ({
        ...prev,
        [sectionId]: {
          question: "",
          answer: "",
          order: 0,
        },
      }));
    } catch (error) {
      console.error("Error adding content:", error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Deletes an FAQ section and its content
   * Shows confirmation modal before deletion
   * @param sectionId - ID of section to delete
   */
  const handleDeleteSection = async (sectionId: string) => {
    openConfirmationModal(
      "Are you sure you want to delete this section?",
      async () => {
        setLoading(true);
        try {
          await deleteFAQSection(sectionId);
          setSections((prevSections) =>
            prevSections.filter((section) => section.id !== sectionId)
          );
          setNewContent((prev) => {
            const updatedContent = { ...prev };
            delete updatedContent[sectionId];
            return updatedContent;
          });
        } catch (error) {
          console.error("Error deleting section:", error);
        } finally {
          setLoading(false);
        }
      }
    );
  };

  /**
   * Deletes content from a section
   * Shows confirmation modal before deletion
   * @param sectionId - ID of section containing content
   * @param content - Content to delete
   */
  const handleDeleteContent = async (sectionId: string, content: FAQContent) => {
    openConfirmationModal(
      "Are you sure you want to delete this content?",
      async () => {
        setLoading(true);
        try {
          await deleteContentFromFAQSection(sectionId, content);
          setSections(prevSections =>
            prevSections.map(section =>
              section.id === sectionId
                ? {
                    ...section,
                    content: section.content.filter(c => c.order !== content.order)
                  }
                : section
            )
          );
        } catch (error) {
          console.error("Error deleting content:", error);
        } finally {
          setLoading(false);
        }
      }
    );
  };

  /**
   * Starts content editing mode
   * @param sectionId - ID of section containing content
   * @param index - Index of content in section
   * @param content - Content to edit
   */
  const handleEditStart = (
    sectionId: string,
    index: number,
    content: FAQContent
  ) => {
    setEditingContent((prev) => ({
      ...prev,
      [sectionId]: { index, content },
    }));
  };

  /**
   * Cancels content editing
   * @param sectionId - ID of section being edited
   */
  const handleEditCancel = (sectionId: string) => {
    setEditingContent((prev) => ({
      ...prev,
      [sectionId]: null,
    }));
  };

  /**
   * Saves edited content
   * Updates both UI and backend
   * @param sectionId - ID of section containing edited content
   */
  const handleEditSave = async (sectionId: string, index: number) => {
    const editingItem = editingContent[sectionId];
    if (!editingItem) return;

    setLoading(true);
    try {
      const section = sections.find((s) => s.id === sectionId);
      if (!section) return;

      const oldContent = section.content[index];
      const newContent = {
        ...editingItem.content,
        order: oldContent.order,
        roles: editingItem.content.roles || [] // Ensure roles is always an array
      };

      await editContentInFAQSection(sectionId, oldContent, newContent);
      
      setSections((prevSections) =>
        prevSections.map((section) =>
          section.id === sectionId
            ? {
                ...section,
                content: section.content.map((c, i) =>
                  i === index ? newContent : c
                ),
              }
            : section
        )
      );

      setEditingContent((prev) => ({
        ...prev,
        [sectionId]: null,
      }));
    } catch (error) {
      console.error("Error saving edited content:", error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles changes during content editing
   * @param sectionId - ID of section being edited
   * @param field - Field being edited (question/answer)
   * @param value - New value for the field
   */
  const handleEditContentChange = (
    sectionId: string,
    field: keyof FAQContent,
    value: string | string[]
  ) => {
    setEditingContent((prev) => {
      const sectionEditingContent = prev[sectionId];
      if (!sectionEditingContent) {
        return prev;
      }

      return {
        ...prev,
        [sectionId]: {
          ...sectionEditingContent,
          content: {
            ...sectionEditingContent.content,
            [field]: value,
          },
        },
      };
    });
  };

  /**
   * Starts section title editing mode
   * @param sectionId - ID of section to edit
   * @param currentTitle - Current title of the section
   */
  const handleEditSectionTitle = (sectionId: string, currentTitle: string) => {
    setEditingSectionTitle((prev) => ({
      ...prev,
      [sectionId]: currentTitle,
    }));
  };

  /**
   * Saves edited section title
   * Updates both UI and backend
   * @param sectionId - ID of section being edited
   */
  const handleSaveSectionTitle = async (
    sectionId: string,
    newTitle: string
  ) => {
    if (!newTitle.trim()) {
      console.error("Title cannot be empty.");
      return;
    }

    try {
      // Make the API call to update the section title
      await updateFAQSectionTitle(sectionId, newTitle);

      // After a successful update, update the section title in local state
      setSections((prevSections) =>
        prevSections.map((section) =>
          section.id === sectionId ? { ...section, title: newTitle } : section
        )
      );

      console.log("FAQ section title updated successfully.");

      // Optionally, reset the editing state for the title
      setEditingSectionTitle((prev) => ({
        ...prev,
        [sectionId]: null,
      }));
    } catch (error) {
      console.error("Failed to update FAQ section title:", error);
    }
  };

  /**
   * Cancels section title editing
   * @param sectionId - ID of section being edited
   */
  const handleCancelEditSectionTitle = (sectionId: string) => {
    setEditingSectionTitle((prev) => ({
      ...prev,
      [sectionId]: null,
    }));
  };

  useEffect(() => {
    // Reset editing state when sections are fetched or initially rendered
    setEditingSectionTitle(() => {
      const newEditingState: Record<string, string | null> = {};
      sections.forEach((section) => {
        newEditingState[section.id] = null; // Ensure all titles are not in editing state by default
      });
      return newEditingState;
    });
  }, [sections]);

  /**
   * Handles drag and drop reordering of content
   * Updates both UI and backend
   * @param event - Drag end event from dnd-kit
   */
  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const [activeSecId, activeIdx] = active.id.toString().split("-");
    const [overSecId, overIdx] = over.id.toString().split("-");

    if (activeSecId !== overSecId) return;

    const section = sections.find((s) => s.id === activeSecId);
    if (!section) return;

    const oldIndex = parseInt(activeIdx);
    const newIndex = parseInt(overIdx);

    setLoading(true);
    const loadingToast = toast.loading("Saving new order...");

    try {
      const newContent = [...section.content];
      const [movedItem] = newContent.splice(oldIndex, 1);
      newContent.splice(newIndex, 0, movedItem);

      // Update order field for all items
      const updatedContent = newContent.map((item, idx) => ({
        ...item,
        order: idx,
      }));

      // Update local state first
      setSections((prevSections) =>
        prevSections.map((s) =>
          s.id === activeSecId
            ? {
                ...s,
                content: updatedContent,
              }
            : s
        )
      );

      // Update backend
      for (let i = 0; i < updatedContent.length; i++) {
        await editContentInFAQSection(
          activeSecId,
          section.content[i],
          updatedContent[i]
        );
      }

      toast.success("Order updated successfully", { id: loadingToast });
    } catch (error) {
      console.error("Error updating content order:", error);
      toast.error("Failed to save new order", { id: loadingToast });

      // Revert changes on error
      setSections((prevSections) =>
        prevSections.map((s) =>
          s.id === activeSecId
            ? {
                ...s,
                content: section.content,
              }
            : s
        )
      );
    } finally {
      setLoading(false);
    }
  };

  // Add this function to handle toggling sections
  const toggleSection = (sectionId: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  return (
    <div className="admin-layout">
      <NavAdmin />
      <Toaster toastOptions={{ position: "top-center" }} />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={`${classes.main} mb-20`}>
          <div className={styles["section-header"]}>
            <div className="flex justify-between items-center mb-4">
              <h2>Manage FAQs</h2>
              <button 
                onClick={() => setShowInstructions(true)}
                className="flex items-center gap-2 px-3 py-2 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
              >
                <FiHelpCircle size={18} />
                <span>Instructions</span>
              </button>
            </div>

            {/* Dropdown for switching FAQ type */}
            <div className={styles["dropdown-container"]}>
              <label htmlFor="faqType">Select:</label>
              <select
                id="faqType"
                value={faqType}
                onChange={(e) => setFaqType(e.target.value as "admin" | "user")}
                className={styles["faq-dropdown"]}
              >
                <option value="admin">Admin FAQ</option>
                <option value="user">User FAQ</option>
              </select>
            </div>

            {/* Add Section Input */}
            <div className={styles["section-input"]}>
              <input
                type="text"
                value={newSectionTitle}
                onChange={(e) => setNewSectionTitle(e.target.value)}
                placeholder="Enter new section title"
              />
              {newSectionTitle.trim() && (
                <button
                  className="!shadow-none !rounded-none"
                  onClick={handleAddSection}
                  disabled={loading}
                >
                  <FiPlus />
                  {loading ? "Saving..." : "Add Section"}
                </button>
              )}
            </div>

            {/* Render Sections */}
            <div className={styles["faq-sections-list"]}>
              {sections.length > 0 ? (
                sections.map((section, index) => (
                  <div key={section.id} className={styles["faq-section"]}>
                    <Suspense fallback="Loading...">
                      <FAQSectionHeader
                        section={section}
                        index={index}
                        isEditing={editingSectionTitle[section.id] !== null}
                        editTitle={editingSectionTitle[section.id]}
                        isExpanded={expandedSections[section.id]}
                        loading={loading}
                        totalSections={sections.length}
                        onToggleExpand={toggleSection}
                        onMoveUp={moveSectionUp}
                        onMoveDown={moveSectionDown}
                        onEditStart={handleEditSectionTitle}
                        onEditSave={handleSaveSectionTitle}
                        onEditCancel={handleCancelEditSectionTitle}
                        onDelete={handleDeleteSection}
                        onTitleChange={(sectionId, value) =>
                          setEditingSectionTitle((prev) => ({
                            ...prev,
                            [sectionId]: value,
                          }))
                        }
                      />
                    </Suspense>
                    {expandedSections[section.id] && (
                      <>
                        <Suspense fallback="Loading...">
                          <FAQSectionContent
                            section={section}
                            editingContent={editingContent}
                            loading={loading}
                            faqType={faqType}
                            onEditStart={handleEditStart}
                            onEditSave={handleEditSave}
                            onEditCancel={handleEditCancel}
                            onEditChange={handleEditContentChange}
                            onDelete={handleDeleteContent} 
                            onDragEnd={handleDragEnd}
                            onTextareaResize={adjustHeight}
                          />
                        </Suspense>

                        <Suspense fallback="Loading...">
                          <AddContentForm
                            sectionId={section.id}
                            content={newContent[section.id]}
                            loading={loading}
                            faqType={faqType}
                            onContentChange={handleContentChange}
                            onAdd={handleAddContent}
                            onTextareaResize={adjustHeight}
                          />
                        </Suspense>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p>No sections available. Add a new section above.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={null}>
        <ConfirmationModal
          isOpen={modalState.isOpen}
          message={modalState.message}
          onConfirm={() => {
            modalState.onConfirm();
            setModalState((prev) => ({ ...prev, isOpen: false }));
          }}
          onCancel={() => setModalState((prev) => ({ ...prev, isOpen: false }))}
        />
      </Suspense>
      <Suspense fallback={null}>
        <FAQInstructionsPanel 
          isOpen={showInstructions} 
          onClose={() => setShowInstructions(false)} 
        />
      </Suspense>
    </div>
  );
};

export default ManageFAQ;
