import { useEffect, useState } from "react";
import { getFAQSections, FAQSection, FAQContent } from "models/ManageFAQ";
import { useAuthContext } from "hooks/useAuthContext";
import styles from "./ManageFAQ.module.css";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import PageWrapper from "components/utility/PageWrapper";
import Header from "components/Header/Header";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

const HighlightedText = ({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm: string;
}) => {
  if (!searchTerm.trim()) return <>{text}</>;

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className={styles["highlight"]}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};

const FAQ = () => {
  const [faqs, setFaqs] = useState<FAQSection[]>([]);
  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { user, profile } = useAuthContext();
  const { organisations } = useOrganisationContext();

  const hasPermission = (content: FAQContent) => {
    // If user is super admin, they can see everything
    if (profile?.access === "admin") {
      return true;
    }

    // If content has roles specified, check if user has any of those roles
    if (content.roles && content.roles.length > 0) {
      // If no user/profile, they can't see role-restricted content
      if (!profile || !user) {
        return false;
      }
      
      // Don't block regular admins if content has both superadmin and other roles
      // Instead, check if they have any of the other required roles
      
      // Get all user roles across all organizations
      const userRoles = organisations.reduce<string[]>((roles, org) => {
        const userRole = org.users?.[user.uid];
        return userRole ? [...roles, userRole] : roles;
      }, []);
      
      // Check if user has any of the required roles (excluding superadmin)
      const hasRequiredRole = content.roles.some(requiredRole => 
        requiredRole !== "superadmin" && userRoles.includes(requiredRole)
      );
      
      // If user has a required role, allow access
      if (hasRequiredRole) return true;
      
      // If content only has superadmin role, only allow superadmins
      if (content.roles.includes("superadmin") && !hasRequiredRole) {
        return profile.access === "admin";
      }
      
      return false;
    }
    
    // If no roles specified, content is visible to everyone
    return true;
  };

  const fetchFAQs = async () => {
    try {
      setLoading(true);
      const fetchedFaqs: FAQSection[] = await getFAQSections();
      
      // Filter to only user FAQs
      const userFaqs = fetchedFaqs
        .filter((section) => section.type === "user")
        .map(section => ({
          ...section,
          // Filter content items to only those the user has permission to see
          content: section.content.filter(hasPermission)
        }))
        // Only keep sections that have at least one visible content item
        .filter(section => section.content.length > 0);
      
      setFaqs(userFaqs);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFAQs();
    // eslint-disable-next-line
  }, [user, organisations]);

  const toggleSection = (sectionId: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredQuestions = faqs.flatMap((section) =>
    section.content
      .filter(
        (item) =>
          hasPermission(item) &&
          (item.question.toLowerCase().includes(searchQuery) ||
           item.answer.toLowerCase().includes(searchQuery))
      )
      .map((item) => ({
        question: item.question,
        answer: item.answer,
        sectionTitle: section.title,
      }))
  );

  return (
    <PageWrapper pageType="groundRules">
      <Header title="FAQ Page" />
      <div
        className={`relative min-h-screen w-full max-w-7xl mx-auto px-4 py-8 md:px-8 ${
          searchQuery.length > 0 ? " mt-[5%] mb-[10%]" : "mt-[13%] md:mt-[5%]"
        } z-50`}
      >
        <div className="max-w-4xl mx-auto">
          <div className={styles["section-input"]}>
            <input
              type="text"
              placeholder="Search questions or answers..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>

          {searchQuery && (
            <div className={styles["search-results"]}>
              {filteredQuestions.length > 0 ? (
                filteredQuestions.map((item, index) => (
                  <div key={index} className={styles["faq-content"]}>
                    <div>
                      <strong>Q:</strong>{" "}
                      <HighlightedText
                        text={item.question}
                        searchTerm={searchQuery}
                      />
                    </div>
                    <div>
                      <strong>A:</strong>{" "}
                      <HighlightedText
                        text={item.answer}
                        searchTerm={searchQuery}
                      />
                    </div>
                    <div className={styles["section-tag"]}>
                      <p className="font-semibold my-2">
                        <HighlightedText
                          text={item.sectionTitle}
                          searchTerm={searchQuery}
                        />
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className={styles["no-results"]}>
                  No matching questions found.
                </p>
              )}
            </div>
          )}

          {!searchQuery && !loading && (
            <div className={styles["faq-sections"]}>
              {faqs.map((section) => (
                <div key={section.id} className={styles["faq-section"]}>
                  <div className={styles["section-title"]}>
                    <div
                      className={`${styles["title-area"]} flex justify-between`}
                      onClick={() => toggleSection(section.id)}
                    >
                      <h3>{section.title}</h3>
                      <button
                        className={`${styles["collapse-btn"]} !shadow-none !bg-transparent`}
                      >
                        {expandedSections[section.id] ? (
                          <MdKeyboardArrowUp size={24} />
                        ) : (
                          <MdKeyboardArrowDown size={24} />
                        )}
                      </button>
                    </div>
                  </div>

                  {expandedSections[section.id] && (
                    <div className={styles["content-list"]}>
                      {Array.isArray(section.content) && section.content.length > 0 ? (
                        section.content
                          .sort((a, b) => a.order - b.order)
                          .filter(hasPermission)
                          .map((content, index) => (
                            <div key={index} className={styles["faq-content"]}>
                              <div>
                                <strong>Q:</strong> {content.question}
                              </div>
                              <div>
                                <strong>A:</strong> {content.answer}
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className={styles["no-content"]}>
                          No content available
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {loading && <p className={styles["loading"]}>Loading FAQs...</p>}
        </div>
      </div>
    </PageWrapper>
  );
};

export default FAQ;
