import { useRef, useState, useEffect, useCallback } from "react";
import { FaSearch, FaTimes, FaStar, FaRegStar } from "react-icons/fa";
import { useAuthContext } from "hooks/useAuthContext";
import {
  addToFavorites,
  removeFromFavorites,
  getAllFavorites,
} from "utils/indexedDB";

interface Option {
  label: string;
  value: string;
  isSpecial?: boolean;
  addedBy?: string;
  createdAt?: number; // Timestamp in milliseconds
}

interface OrganisationDropdownProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  setHasModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrganisationDropdown = ({
  options,
  value,
  onChange,
  placeholder = "SELECT AN ORGANISATION",
  setHasModal,
}: OrganisationDropdownProps) => {
  const { user } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState<
    "all" | "mine" | "new" | "favorites"
  >("all");
  const [favorites, setFavorites] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  // Check if an organization is new (less than 2 days old)
  const isNewOrganization = (createdAt?: number): boolean => {
    if (!createdAt) return false;
    const twoDaysAgo = Date.now() - 2 * 24 * 60 * 60 * 1000;
    return createdAt > twoDaysAgo;
  };

  // Load favorites from IndexedDB
  const loadFavorites = useCallback(async () => {
    try {
      setIsLoading(true);
      const favs = await getAllFavorites();
      setFavorites(favs);
    } catch (error) {
      console.error("Error loading favorites:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Check if an organization is favorited
  const isFavorite = (optionValue: string): boolean => {
    return favorites.includes(optionValue);
  };

  // Toggle favorite status
  const toggleFavorite = async (option: Option, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent triggering the handleSelect

    try {
      if (isFavorite(option.value)) {
        await removeFromFavorites(option.value);
        setFavorites((prev) => prev.filter((id) => id !== option.value));
      } else {
        await addToFavorites(option.value, option.label);
        setFavorites((prev) => [...prev, option.value]);
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  // Load favorites on component mount
  useEffect(() => {
    loadFavorites();
  }, [loadFavorites]);

  const filteredOptions = options.filter((option) => {
    const matchesSearch = option.label
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Filter logic based on selected filter
    let matchesFilter = false;
    switch (activeFilter) {
      case "all":
        matchesFilter = true;
        break;
      case "mine":
        matchesFilter = option.addedBy === user?.email;
        break;
      case "new":
        matchesFilter = isNewOrganization(option.createdAt);
        break;
      case "favorites":
        matchesFilter = isFavorite(option.value);
        break;
    }

    return matchesSearch && matchesFilter;
  });

  // Prevent body scrolling when modal is open
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalStyle;
    }

    if (setHasModal) {
      setHasModal(isOpen);
    }

    return () => {
      document.body.style.overflow = originalStyle;
    };
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setSearchTerm("");
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      searchInputRef.current?.focus();
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  const handleSelect = (option: Option) => {
    onChange(option.value);
    setIsOpen(false);
    setSearchTerm("");
  };

  const handleFilterChange = (filter: "all" | "mine" | "new" | "favorites") => {
    setActiveFilter(filter);
  };

  const selectedOption = options.find((opt) => opt.value === value);

  return (
    <div className="!relative !inline-block">
      {/* Main Trigger - Dynamic width */}
      <div
        ref={triggerRef}
        onClick={() => setIsOpen(true)}
        className="!flex !w-full !h-[50px] !bg-white !border-2 !border-[#EB8181] !text-[#EB8181] !rounded-full !items-center !justify-between !cursor-pointer !transition-all hover:!shadow-md !px-6"
      >
        <div className="!flex !items-center !gap-3">
          <svg
            className="!w-5 !h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
              clipRule="evenodd"
            />
          </svg>
          <span className="!text-[15px] !font-medium">
            {selectedOption?.label || placeholder}
          </span>
        </div>
        <div className="!flex !items-center !gap-2">
          {selectedOption && (
            <>
              {isFavorite(selectedOption.value) && (
                <span className="!text-xs !text-yellow-500 !bg-yellow-50 !px-2 !py-1 !rounded-full !flex !items-center !gap-1">
                  <FaStar size={10} />
                  Favorite
                </span>
              )}
              <span className="!text-xs !bg-[#EB8181]/10 !px-2 !py-1 !rounded-full">
                Selected
              </span>
            </>
          )}
          <svg
            className="!w-5 !h-5 !transition-transform"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>

      {/* Popup Modal */}
      {isOpen && (
        <div className="!fixed !inset-0 !bg-black/30 !flex !items-center !justify-center !z-50">
          <div
            ref={modalRef}
            className="!w-lg !bg-white !rounded-lg !overflow-hidden"
          >
            {/* Search Header */}
            <div className="!flex !items-center !justify-between !px-4 !py-2 !border-b">
              <div className="!flex-1 !flex !items-center !gap-2">
                <FaSearch className="!text-gray-400" size={14} />
                <input
                  ref={searchInputRef}
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search organisations..."
                  className="!w-full !border-none !outline-none !text-[14px] !placeholder-gray-400"
                />
              </div>
              <button
                onClick={() => {
                  setIsOpen(false);
                  setSearchTerm("");
                }}
                className="!text-gray-400 hover:!text-gray-600 !bg-transparent !border-0 !p-0 !shadow-none !outline-none"
              >
                <FaTimes size={16} />
              </button>
            </div>

            {/* Filter Tabs */}
            <div className="!flex !items-center !gap-2 !px-4 !py-3 !overflow-x-auto">
              <button
                onClick={() => handleFilterChange("all")}
                className={`
                  !px-4 !py-1.5 !text-[13px] !rounded-full !transition-colors !duration-200 !border-0 !shadow-none !outline-none !whitespace-nowrap
                  ${
                    activeFilter === "all"
                      ? "!bg-[#EB8181] !text-white"
                      : "!text-gray-600 !bg-gray-100 hover:!bg-gray-200"
                  }
                `}
              >
                All Organisations
              </button>
              <button
                onClick={() => handleFilterChange("favorites")}
                className={`
                  !px-4 !py-1.5 !text-[13px] !rounded-full !transition-colors !duration-200 !border-0 !shadow-none !outline-none !whitespace-nowrap !flex !items-center !gap-1
                  ${
                    activeFilter === "favorites"
                      ? "!bg-[#EB8181] !text-white"
                      : "!text-gray-600 !bg-gray-100 hover:!bg-gray-200"
                  }
                `}
              >
                <FaStar
                  size={10}
                  className={
                    activeFilter === "favorites"
                      ? "!text-white"
                      : "!text-yellow-500"
                  }
                />
                Favorites
              </button>
              <button
                onClick={() => handleFilterChange("mine")}
                className={`
                  !px-4 !py-1.5 !text-[13px] !rounded-full !transition-colors !duration-200 !border-0 !shadow-none !outline-none !whitespace-nowrap
                  ${
                    activeFilter === "mine"
                      ? "!bg-[#EB8181] !text-white"
                      : "!text-gray-600 !bg-gray-100 hover:!bg-gray-200"
                  }
                `}
              >
                Added by Me
              </button>
              <button
                onClick={() => handleFilterChange("new")}
                className={`
                  !px-4 !py-1.5 !text-[13px] !rounded-full !transition-colors !duration-200 !border-0 !shadow-none !outline-none !whitespace-nowrap
                  ${
                    activeFilter === "new"
                      ? "!bg-[#EB8181] !text-white"
                      : "!text-gray-600 !bg-gray-100 hover:!bg-gray-200"
                  }
                `}
              >
                Recently Added
              </button>
            </div>

            {/* Results Section */}
            <div className="!p-4">
              <div className="!flex !items-center !justify-between !mb-2">
                <div className="!text-[13px] !font-medium !text-gray-700">
                  Top Results
                </div>
                {activeFilter === "mine" && (
                  <div className="!text-[12px] !text-gray-500">
                    Showing your organisations
                  </div>
                )}
                {activeFilter === "new" && (
                  <div className="!text-[12px] !text-gray-500">
                    Added in the last 2 days
                  </div>
                )}
                {activeFilter === "favorites" && (
                  <div className="!text-[12px] !text-gray-500">
                    Your favorite organisations
                  </div>
                )}
              </div>
              <div className="!max-h-[300px] !overflow-y-auto">
                {isLoading ? (
                  <div className="!flex !items-center !justify-center !py-8">
                    <div className="!w-6 !h-6 !border-2 !border-gray-300 !border-t-[#EB8181] !rounded-full !animate-spin"></div>
                  </div>
                ) : filteredOptions.length > 0 ? (
                  <div className="!space-y-1">
                    {filteredOptions.map((option) => (
                      <div
                        key={option.value}
                        onClick={() => handleSelect(option)}
                        className={`
                          !px-3 !py-2 !rounded !cursor-pointer !text-[13px] !flex !items-center !justify-between
                          !text-gray-700 hover:!bg-gray-50 !transition-colors
                        `}
                      >
                        <div className="!flex !items-center !gap-2">
                          <span>{option.label}</span>
                          {isNewOrganization(option.createdAt) && (
                            <span className="!text-[10px] !bg-green-500 !text-white !px-1.5 !py-0.5 !rounded-full !uppercase !font-medium">
                              New
                            </span>
                          )}
                          {activeFilter === "mine" && (
                            <span className="!text-[11px] !text-gray-400">
                              (Added by you)
                            </span>
                          )}
                        </div>
                        <div className="!flex !items-center !gap-2">
                          {option.isSpecial && (
                            <span className="!text-[11px] !text-[#FFB571] !font-medium">
                              Special
                            </span>
                          )}
                          <button
                            onClick={(e) => toggleFavorite(option, e)}
                            className="!p-1 !text-yellow-500 hover:!text-yellow-600 !transition-colors !border-0 !bg-transparent !shadow-none !outline-none"
                            title={
                              isFavorite(option.value)
                                ? "Remove from favorites"
                                : "Add to favorites"
                            }
                          >
                            {isFavorite(option.value) ? (
                              <FaStar size={14} />
                            ) : (
                              <FaRegStar size={14} />
                            )}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="!flex !flex-col !items-center !justify-center !py-8">
                    <div className="!w-12 !h-12 !mb-4">
                      {activeFilter === "favorites" ? (
                        <FaStar className="!w-full !h-full !text-gray-300" />
                      ) : (
                        <svg
                          className="!w-full !h-full !text-gray-300"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                          />
                        </svg>
                      )}
                    </div>
                    <p className="!text-[13px] !text-gray-500">
                      {activeFilter === "mine"
                        ? "You haven't added any organisations yet"
                        : activeFilter === "new"
                        ? "No recently added organisations"
                        : activeFilter === "favorites"
                        ? "No favorite organisations yet"
                        : "No organisations found"}
                    </p>
                    <p className="!text-[13px] !text-gray-400">
                      {activeFilter !== "all"
                        ? "Try switching to 'All Organisations'"
                        : "Please try again or create a new project"}
                    </p>
                    <div className="!flex !gap-2 !mt-4">
                      {activeFilter !== "all" && (
                        <button
                          onClick={() => handleFilterChange("all")}
                          className="!px-4 !py-2 !text-[13px] !text-gray-600 !bg-transparent !rounded hover:!bg-gray-50 !border-0 !shadow-none !outline-none"
                        >
                          Show All
                        </button>
                      )}
                      <button
                        onClick={() => setSearchTerm("")}
                        className="!px-4 !py-2 !text-[13px] !text-gray-600 !bg-transparent !rounded hover:!bg-gray-50 !border-0 !shadow-none !outline-none"
                      >
                        Clear search
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganisationDropdown;
