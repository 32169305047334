import {
  BiComment,
  BiHighlight,
  BiErrorCircle,
  BiUserCircle,
  BiCog,
} from "react-icons/bi";
import { MdEmail, MdGroups } from "react-icons/md";
import { BsStar } from "react-icons/bs";
import { FaMobileAlt, FaDesktop, FaGlobe, FaCalendarAlt } from "react-icons/fa";

interface IconComponentProps {
  text: string | undefined;
  label?: string;
}

const IconWrapper = ({
  icon: Icon,
  text,
  label,
}: {
  icon: React.ElementType;
  text: string | undefined;
  label?: string;
}) => (
  <div className="flex items-center gap-2 mb-2">
    <Icon className="text-gray-500 shrink-0" size={20} />
    <span className="text-sm text-gray-700">{label || text || "N/A"}</span>
  </div>
);

export const IconComponents = {
  Organisation: ({ text }: IconComponentProps) => (
    <IconWrapper icon={MdGroups} text={text} />
  ),
  Feedback: ({ text }: IconComponentProps) => (
    <IconWrapper icon={BiComment} text={text} />
  ),
  Suggestions: ({ text }: IconComponentProps) => (
    <IconWrapper icon={BiHighlight} text={text} />
  ),
  Rating: ({ text }: IconComponentProps) => (
    <IconWrapper icon={BsStar} text={text} />
  ),
  Problem: ({ text }: IconComponentProps) => (
    <IconWrapper icon={BiErrorCircle} text={text} />
  ),
  Device: ({ text }: IconComponentProps) => (
    <IconWrapper icon={FaMobileAlt} text={text} />
  ),
  DeviceModel: ({ text }: IconComponentProps) => (
    <IconWrapper icon={FaDesktop} text={text} />
  ),
  OS: ({ text }: IconComponentProps) => (
    <IconWrapper icon={BiCog} text={text} />
  ),
  Browser: ({ text }: IconComponentProps) => (
    <IconWrapper icon={FaGlobe} text={text} />
  ),
  Date: ({ text }: IconComponentProps) => (
    <IconWrapper icon={FaCalendarAlt} text={text} />
  ),
  User: ({ text }: IconComponentProps) => (
    <IconWrapper icon={BiUserCircle} text={text} />
  ),
  Email: ({ text }: IconComponentProps) => (
    <IconWrapper icon={MdEmail} text={text} />
  ),
};
