import { Feedback } from "../../types";
import { IconComponents } from "../FeedbackList/IconComponents";
import { formatDate } from "../../utils/formatters";
import { useState } from "react";
import Lightbox from "react-spring-lightbox";
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";

interface ViewDetailsModalProps {
  feedback: Feedback;
  onClose: () => void;
}

const ViewDetailsModal = ({ feedback, onClose }: ViewDetailsModalProps) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const gotoPrevious = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const gotoNext = () => {
    if (currentImageIndex + 1 < feedback.files.length) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const images = feedback.files.map((file) => ({
    src: file,
    alt: "Feedback Image",
  }));

  const categories = [
    {
      title: "Basic Information",
      items: [
        { icon: "Organisation", text: feedback.organisation },
        { icon: "User", text: feedback.userName },
        { icon: "Email", text: feedback.userEmail },
        { icon: "Date", text: formatDate(feedback.timestamp) },
      ],
    },
    {
      title: "Feedback Details",
      items: [
        { icon: "Feedback", text: feedback.feedback },
        { icon: "Suggestions", text: feedback.suggestions },
        { icon: "Rating", text: feedback.rating.toString() },
        { icon: "Problem", text: feedback.problem },
      ],
    },
    {
      title: "Device Information",
      items: [
        { icon: "Device", text: feedback.device },
        { icon: "DeviceModel", text: feedback.deviceModel },
        { icon: "OS", text: feedback.os },
        { icon: "Browser", text: feedback.browser },
        {
          icon: "Browser",
          text: feedback.otherBrowser,
          label: "Other Browser",
        },
      ],
    },
  ];

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl p-6 m-4">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            Feedback Details
          </h3>
          <button
            onClick={onClose}
            className="text-2xl text-gray-400 hover:text-gray-600"
          >
            ×
          </button>
        </div>

        <div className="space-y-6 max-h-[70vh] overflow-y-auto px-2">
          {/* Uploaded Files Section */}
          {feedback.files.length > 0 && (
            <div className="space-y-3">
              <h4 className="font-medium text-gray-700">Uploaded Files</h4>
              <div className="grid grid-cols-2 gap-4">
                {feedback.files.map((file, idx) => (
                  <img
                    key={idx}
                    src={file}
                    alt={`Upload ${idx + 1}`}
                    className="w-full h-48 object-cover rounded-lg border border-gray-200 cursor-pointer hover:opacity-90 transition-opacity"
                    onClick={() => {
                      setCurrentImageIndex(idx);
                      setIsLightboxOpen(true);
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Lightbox */}
          <Lightbox
            isOpen={isLightboxOpen}
            onPrev={gotoPrevious}
            onNext={gotoNext}
            images={images}
            currentIndex={currentImageIndex}
            onClose={() => setIsLightboxOpen(false)}
            renderHeader={() => (
              <div className="flex justify-end p-4">
                <button
                  className="text-white hover:text-gray-300 transition-colors"
                  onClick={() => setIsLightboxOpen(false)}
                >
                  <FaTimes size={24} />
                </button>
              </div>
            )}
            renderPrevButton={() => (
              <button
                type="button"
                className={`absolute z-10 left-4 top-1/2 -translate-y-1/2 p-3 bg-white/10 rounded-full hover:bg-white/20 transition-colors ${
                  currentImageIndex === 0 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (currentImageIndex > 0) {
                    gotoPrevious();
                  }
                }}
                disabled={currentImageIndex === 0}
              >
                <FaChevronLeft size={24} className="text-white" />
              </button>
            )}
            renderNextButton={() => (
              <button
                className={`absolute right-4 top-1/2 -translate-y-1/2 p-3 bg-white/10 rounded-full hover:bg-white/20 transition-colors ${
                  currentImageIndex === feedback.files.length - 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  gotoNext();
                }}
                disabled={currentImageIndex === feedback.files.length - 1}
              >
                <FaChevronRight size={24} className="text-white" />
              </button>
            )}
            className="bg-black/90"
            style={{ background: "rgba(0, 0, 0, 0.9)" }}
          />

          {/* Categorized Information */}
          {categories.map((category) => (
            <div key={category.title} className="space-y-3">
              <h4 className="font-medium text-gray-700 border-b pb-2">
                {category.title}
              </h4>
              <div className="grid grid-cols-2 gap-4">
                {category.items.map((item) => (
                  <div key={item.label || item.icon}>
                    {IconComponents[item.icon as keyof typeof IconComponents]({
                      text: item.text,
                      label: item.label,
                    })}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 pt-4 border-t flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-[#EB8181] bg-[#EB8181]/10 rounded-md hover:bg-[#EB8181]/20 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsModal;
