import classes from "./SettingsIntentions.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import EmptySpace from "components/utility/EmptySpace";
import SubmitIntention from "components/SettingIntentions/SubmitIntention";
import Instruction from "components/utility/Instruction";
import React, { useEffect, useState, useRef } from "react";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useOrganisationIntentions } from "hooks/useOrganisationIntentions";
// import { Intention } from "interface/IntentionInterface";
import { IoMdRefreshCircle } from "react-icons/io";
import OthersIntentionRandom from "components/SettingIntentions/OthersIntentionRandom";

export default function SettingRandomIntentionsComponent({
  backAndNextHandler,
}: ComponentProps) {
  const { profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { intentions } = useOrganisationIntentions();
  const [question, setQuestion] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [usedIndices, setUsedIndices] = useState<number[]>([]);
  const isInitialized = useRef(false);

  // Initialize questions
  const initializeQuestions = () => {
    if (!intentions?.length) return;
    const randomIndex = Math.floor(Math.random() * intentions.length);
    setQuestion(intentions[randomIndex].question);
    setUsedIndices([randomIndex]);
  };

  // Handle manual refresh click
  const randomizeIntentionQuestion = () => {
    try {
      setIsResetting(true);
      if (!intentions?.length) return;

      // Get available indices (indices not yet used)
      const availableIndices = Array.from(Array(intentions.length).keys())
        .filter(index => !usedIndices.includes(index));

      // If all indices have been used, reset and exclude current index
      if (availableIndices.length === 0) {
        const currentIndex = intentions.findIndex(i => i.question === question);
        const newAvailableIndices = Array.from(Array(intentions.length).keys())
          .filter(index => index !== currentIndex);
        
        const randomIndex = newAvailableIndices[Math.floor(Math.random() * newAvailableIndices.length)];
        setQuestion(intentions[randomIndex].question);
        setUsedIndices([randomIndex]);
        return;
      }

      // Select random index from available ones
      const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
      setQuestion(intentions[randomIndex].question);
      setUsedIndices(prev => [...prev, randomIndex]);
      
    } catch (error) {
      console.error("Error randomizing questions:", error);
    } finally {
      setIsResetting(false);
    }
  };

  // Initialize questions when component mounts or intentions change
  useEffect(() => {
    if (
      selectedOrganisation?.randomizeIntentions &&
      intentions?.length &&
      !isInitialized.current
    ) {
      isInitialized.current = true;
      initializeQuestions();
    }
    // eslint-disable-next-line
  }, [intentions, selectedOrganisation?.randomizeIntentions]);

  return (
    <React.Fragment>
      <EmptySpace height={"2rem"} />

      {selectedOrganisation?.randomizeIntentions && (
        <div className={`${classes.container} flex items-center justify-center`}>
          <div className="w-full">
            <Instruction text={question} />
          </div>
          {intentions && intentions.length > 1 && (
            <button
              onClick={randomizeIntentionQuestion}
              disabled={isResetting}
              className="transition-opacity"
            >
              <IoMdRefreshCircle
                size={35}
                className={`${
                  isResetting ? "opacity-50" : ""
                } text-[var(--main-colour)]`}
              />
            </button>
          )}
        </div>
      )}

      <EmptySpace height={"2rem"} />
      <SubmitIntention profile={profile} question={question} />
      <EmptySpace height={"5px"} />
      <OthersIntentionRandom
        randomize={
          (selectedOrganisation && selectedOrganisation.randomizeIntentions) ||
          false
        }
      />
      <EmptySpace height={"30px"} />
      <BackAndNext {...backAndNextHandler} />
    </React.Fragment>
  );
}
