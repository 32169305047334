import { useEffect, useState } from "react";
import { getFAQSections, FAQSection, FAQContent } from "models/ManageFAQ";
import styles from "./ManageFAQ.module.css";
import NavAdmin from "components/Admin/NavAdmin";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { useAuthContext } from "hooks/useAuthContext";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

const HighlightedText = ({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm: string;
}) => {
  if (!searchTerm.trim()) return <>{text}</>;

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className={styles["highlight"]}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};

const AdminFAQ = () => {
  const [faqs, setFaqs] = useState<FAQSection[]>([]);
  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { user, profile } = useAuthContext();
  const { organisations } = useOrganisationContext();

  const hasPermission = (content: FAQContent) => {
    // If user is super admin, they can see everything
    if (profile?.access === "admin") return true;

    // If content has roles specified, check if user has any of those roles
    if (content.roles && content.roles.length > 0) {
      // If no user/profile, they can't see role-restricted content
      if (!profile || !user) return false;

      // Don't block regular admins if content has both superadmin and other roles
      // Instead, check if they have any of the other required roles

      // Get all user roles across all organizations
      const userRoles = organisations.reduce<string[]>((roles, org) => {
        const userRole = org.users?.[user.uid];
        return userRole ? [...roles, userRole] : roles;
      }, []);

      // Check if user has any of the required roles (excluding superadmin)
      const hasRequiredRole = content.roles.some(
        (requiredRole) =>
          requiredRole !== "superadmin" && userRoles.includes(requiredRole)
      );

      // If user has a required role, allow access
      if (hasRequiredRole) return true;

      // If content only has superadmin role, only allow superadmins
      if (content.roles.includes("superadmin") && !hasRequiredRole) {
        return profile.access === "admin";
      }

      return false;
    }

    // If no roles specified, content is visible to everyone
    return true;
  };

  const filterContentByRoles = (sections: FAQSection[]): FAQSection[] => {
    if (!Array.isArray(sections) || sections.length === 0) {
      return [];
    }

    return sections
      .map((section) => ({
        ...section,
        content: Array.isArray(section.content)
          ? section.content.filter(hasPermission)
          : [],
      }))
      .filter((section) => section.content.length > 0);
  };

  const fetchFAQs = async () => {
    try {
      setLoading(true);
      const fetchedFaqs: FAQSection[] = await getFAQSections("admin");
      const filteredFaqs = filterContentByRoles(fetchedFaqs);
      setFaqs(filteredFaqs);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
      setFaqs([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFAQs();
    // eslint-disable-next-line
  }, [user, profile, organisations]);

  const toggleSection = (sectionId: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredQuestions = faqs.flatMap((section) =>
    Array.isArray(section.content)
      ? section.content
          .filter(
            (item) =>
              hasPermission(item) &&
              (item.question?.toLowerCase().includes(searchQuery) ||
                item.answer?.toLowerCase().includes(searchQuery))
          )
          .map((item) => ({
            question: item.question,
            answer: item.answer,
            sectionTitle: section.title,
            roles: item.roles,
          }))
      : []
  );

  return (
    <div className="admin-layout">
      <NavAdmin />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={`${classes.main} mb-20`}>
          <div className={styles["section-header"]}>
            <h2>Admin FAQ</h2>

            <div className={styles["section-input"]}>
              <input
                type="text"
                placeholder="Search questions or answers..."
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>

            {searchQuery && (
              <div className={styles["search-results"]}>
                {filteredQuestions.length > 0 ? (
                  filteredQuestions.map((item, index) => (
                    <div key={index} className={styles["faq-content"]}>
                      <div>
                        <strong>Q:</strong>{" "}
                        <HighlightedText
                          text={item.question}
                          searchTerm={searchQuery}
                        />
                      </div>
                      <div>
                        <strong>A:</strong>{" "}
                        <HighlightedText
                          text={item.answer}
                          searchTerm={searchQuery}
                        />
                      </div>
                      <div className={styles["section-tag"]}>
                        <p className="font-semibold my-2">
                          <HighlightedText
                            text={item.sectionTitle}
                            searchTerm={searchQuery}
                          />
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className={styles["no-results"]}>
                    No matching questions found.
                  </p>
                )}
              </div>
            )}

            {!searchQuery && !loading && (
              <div className={styles["faq-sections"]}>
                {faqs.map((section) => (
                  <div key={section.id} className={styles["faq-section"]}>
                    <div className={styles["section-title"]}>
                      <div
                        className={styles["title-area"]}
                        onClick={() => toggleSection(section.id)}
                      >
                        <h3>{section.title}</h3>
                        <button
                          className={`${styles["collapse-btn"]} !shadow-none !bg-transparent`}
                        >
                          {expandedSections[section.id] ? (
                            <MdKeyboardArrowUp size={24} />
                          ) : (
                            <MdKeyboardArrowDown size={24} />
                          )}
                        </button>
                      </div>
                    </div>

                    {expandedSections[section.id] && (
                      <div className={styles["content-list"]}>
                        {Array.isArray(section.content) &&
                        section.content.length > 0 ? (
                          section.content
                            .sort((a, b) => a.order - b.order)
                            .filter(hasPermission)
                            .map((content, index) => (
                              <div
                                key={index}
                                className={styles["faq-content"]}
                              >
                                <div>
                                  <strong>Q:</strong> {content.question}
                                </div>
                                <div>
                                  <strong>A:</strong> {content.answer}
                                </div>
                                {content.roles &&
                                  content.roles.length > 0 &&
                                  profile?.access === "admin" && (
                                    <div className="text-sm text-gray-500 mt-2">
                                      Visible to: {content.roles.join(", ")}
                                    </div>
                                  )}
                              </div>
                            ))
                        ) : (
                          <div className={styles["no-content"]}>
                            No content available
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {loading && <p className={styles["loading"]}>Loading FAQs...</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFAQ;
