/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useMemo, useState } from "react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useResourcesOrganisation } from "hooks/useResourcesOrganisation";
import PageWrapper from "components/utility/PageWrapper";
import Header from "components/Header/Header";
import { ResourceInterface } from "interface/ResourceInterface";
import { useAuthContext } from "hooks/useAuthContext";
import ResourceCard from "components/Resources/ResourceCard";
import DefaultResourceCard from "components/Resources/DefaultResourceCard";
import EmptySpace from "components/utility/EmptySpace";

import OrganisationDropdown from "components/utility/DropDown/OrganisationDropdown";

/**
 * Page that shows the ground rules for users when they first start a GC session
 */
const ResourceLandingPage = () => {
  const { selectedOrganisation, organisations, selectOrganisationByName } =
    useOrganisationContext();
  const { resources } = useResourcesOrganisation();
  const { user, profile } = useAuthContext();
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [hasModal, setHasModal] = useState(false);

  const hasPermission = (resource: ResourceInterface) => {
    // If user is super admin, they can see everything
    if (profile?.access === "admin") {
      return true;
    }

    // If no roles specified, resource is visible to everyone
    if (!resource.roleVisibility || resource.roleVisibility.length === 0) {
      return true;
    }

    // If no user/profile or no selected organization, they can't see role-restricted content
    if (!user || !selectedOrganisation) {
      return false;
    }

    // Get user's role in the selected organization
    const userRole = selectedOrganisation.users?.[user.uid];

    // If user has no role in this organization, they can't see role-restricted content
    if (!userRole) {
      return false;
    }

    // Check if user's role in the selected organization matches any of the required roles
    return resource.roleVisibility.includes(userRole);
  };

  // Filter resources based on permissions
  const filteredResources = useMemo(() => {
    return resources.filter(hasPermission);
    // eslint-disable-next-line
  }, [resources, user, selectedOrganisation?.users]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  const defaultResources = [
    {
      url: "https://www.imh.com.sg/CHAT/Pages/default.aspx",
      title: "IMH CHAT",
    },
    { url: "https://mindline.sg/", title: "Mindline.sg" },
    {
      url: "https://www.healthhub.sg/programmes/186/MindSG/Discover",
      title: "HealthHub SG",
    },
    {
      url: "https://for.sg/aic-mhtop5",
      title: "AIC Mental Health Resources",
    },
    {
      url: "https://for.sg/mh-mindmattersdir",
      title: "Mental Health Services Directory",
    },
    {
      url: "https://for.sg/mh-mindmattersinfo",
      title: "Mind Matters Directory",
    },
  ];
  const isSuperAdmin = profile && profile?.access;
  const dropdownOptions = organisations
    .filter((org) => !org.isAType)
    .filter(
      (org) =>
        (!isSuperAdmin &&
          profile &&
          org.users[profile.id] === "administrator") ||
        isSuperAdmin
    )
    .map((org) => {
      return {
        label: org.name,
        value: org.name,
        isSpecial: org.name === "ADD TEST",
        addedBy: org.addedBy || "",
        createdAt: org.createdAt ? org.createdAt.toMillis() : undefined,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <PageWrapper pageType={"groundRules"}>
      <Header hasModal={showPdfPreview || hasModal} />
      <div
        className="max-w-7xl mx-auto px-6 py-12 mb-[100px]"
        style={{ marginTop: "8vh" }}
      >
        {/* Header Section */}
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">
            Resources Hub
          </h1>
          <p className="text-lg text-gray-600">
            Access curated resources and valuable information to support your
            journey
          </p>
        </div>

        {/* Organization Selector */}
        <div className="flex justify-center items-center max-w-2xl mx-auto mb-12">
          <OrganisationDropdown
            options={dropdownOptions}
            value={selectedOrganisation?.name ?? ""}
            onChange={selectOrganisationByName}
            setHasModal={setHasModal}
          />
        </div>

        <div className="space-y-12">
          {/* Organisation Resources Section */}
          {selectedOrganisation && filteredResources.length > 0 && (
            <section>
              <div className="flex items-center justify-between mb-8">
                <div className="flex items-center gap-3">
                  <div className="w-2.5 h-2.5 rounded-full bg-[#EB8181]" />
                  <h2 className="text-2xl font-semibold text-gray-800">
                    Organisation Resources
                  </h2>
                </div>
                <div className="text-sm font-medium text-gray-500 bg-gray-50 px-4 py-2 rounded-full">
                  {filteredResources.length}{" "}
                  {filteredResources.length === 1 ? "resource" : "resources"}{" "}
                  available
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredResources.map((resource) => (
                  <ResourceCard
                    key={resource.id}
                    resource={resource}
                    setShowPdfPreview={setShowPdfPreview}
                    showPdfPreview={showPdfPreview}
                  />
                ))}
              </div>
            </section>
          )}

          {/* General Resources Section */}
          {(!selectedOrganisation ||
            selectedOrganisation?.useDefaultResources !== false) && (
            <section>
              <div className="flex items-center justify-between mb-8">
                <div className="flex items-center gap-3">
                  <div className="w-2.5 h-2.5 rounded-full bg-gray-400" />
                  <h2 className="text-2xl font-semibold text-gray-800">
                    General Resources
                  </h2>
                </div>
                <div className="text-sm font-medium text-gray-500 bg-gray-50 px-4 py-2 rounded-full">
                  {defaultResources.length} default resources
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {defaultResources.map((resource, index) => (
                  <DefaultResourceCard key={index} resource={resource} />
                ))}
              </div>
            </section>
          )}
        </div>
      </div>
      <EmptySpace height="100px" />
    </PageWrapper>
  );
};

export default ResourceLandingPage;
