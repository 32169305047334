import { OrganisationContextProvider } from "context/OrganisationContext";
import ResourceLandingPage from "./ResourceLandingPage";

const ResourceContainer = () => {
  return (
    <OrganisationContextProvider>
      <ResourceLandingPage />
    </OrganisationContextProvider>
  );
};

export default ResourceContainer;
