import React, { useState } from "react";
import classes from "./EntryCards.module.css";

interface Props {
  randomize?: boolean;
  userName: string;
  intention: string;
  intentionQuestion?: string;
}

const EntryCardRandom: React.FC<Props> = ({
  randomize,
  userName,
  intention,
  intentionQuestion,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [intentionExpanded, setIntentionExpanded] = useState(false);
  // Track expanded state for each step individually
  const [expandedSteps, setExpandedSteps] = useState<Record<number, boolean>>({});
  
  // Character limit before showing "Read more..."
  const CHARACTER_LIMIT = 150;
  
  // Check if text needs to be truncated
  const isLongText = intentionQuestion && intentionQuestion.length > CHARACTER_LIMIT;
  const isLongIntention = intention && intention.length > CHARACTER_LIMIT;
  
  // Get the displayed text based on expanded state
  const getDisplayedText = (text: string) => {
    if (!isLongText || expanded) return text;
    return text.substring(0, CHARACTER_LIMIT) + "...";
  };

  // Get displayed text for intention based on its expanded state
  const getIntentionDisplayedText = (text: string) => {
    if (!isLongIntention || intentionExpanded) return text;
    return text.substring(0, CHARACTER_LIMIT) + "...";
  };

  // Get displayed text for a step based on its expanded state
  const getStepDisplayedText = (text: string, index: number) => {
    const isStepLong = text.length > CHARACTER_LIMIT;
    if (!isStepLong || expandedSteps[index]) return text;
    return text.substring(0, CHARACTER_LIMIT) + "...";
  };

  // Toggle expanded state for a specific step
  const toggleStepExpanded = (index: number) => {
    setExpandedSteps(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Split the intention question into steps if it contains line breaks
  const steps = intentionQuestion
    ? intentionQuestion.split(/\r?\n/).filter((step) => step.trim() !== "")
    : [];

  return (
    <>
      <div className={classes.content}>
        <div className={`${classes.header} text-xl font-semibold`}>
          {userName}
        </div>
        <div className={classes.body}>
          {randomize && intentionQuestion && (
            <>
              <div className="mb-4">
                <span className="font-semibold ">Q : </span>
                {steps.length > 1 ? (
                  <ol className="list-decimal pl-6">
                    {steps.map((step, index) => {
                      const isStepLong = step.length > CHARACTER_LIMIT;
                      return (
                        <li key={index} className="mb-2 text-left">
                          {getStepDisplayedText(step, index)}
                          {isStepLong && (
                            <button 
                              onClick={(e) => {
                                e.preventDefault();
                                toggleStepExpanded(index);
                              }}
                              className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
                            >
                              {expandedSteps[index] ? "Read less" : "Read more..."}
                            </button>
                          )}
                        </li>
                      );
                    })}
                  </ol>
                ) : (
                  <div>
                    <span className="font-semibold">{getDisplayedText(intentionQuestion)}</span>
                    {isLongText && (
                      <button 
                        onClick={() => setExpanded(!expanded)}
                        className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
                      >
                        {expanded ? "Read less" : "Read more..."}
                      </button>
                    )}
                  </div>
                )}
              </div>
              <p>
                <span className="font-semibold ">A : </span> 
                {getIntentionDisplayedText(intention)}
                {isLongIntention && (
                  <button 
                    onClick={() => setIntentionExpanded(!intentionExpanded)}
                    className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
                  >
                    {intentionExpanded ? "Read less" : "Read more..."}
                  </button>
                )}
              </p>
            </>
          )}
          {!randomize && (
            <p>
              {getIntentionDisplayedText(intention)}
              {isLongIntention && (
                <button 
                  onClick={() => setIntentionExpanded(!intentionExpanded)}
                  className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
                >
                  {intentionExpanded ? "Read less" : "Read more..."}
                </button>
              )}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default EntryCardRandom;
