import React, { useState } from "react";
import classes from "./EntryCards.module.css";

interface Props {
  randomize?: boolean;
  userName: string;
  intention: string;
  intentionQuestion?: string;
}

const EntryCards: React.FC<Props> = ({
  randomize,
  userName,
  intention,
  intentionQuestion,
}) => {
  const [intentionExpanded, setIntentionExpanded] = useState(false);
  const [questionExpanded, setQuestionExpanded] = useState(false);

  // Character limit before showing "Read more..."
  const CHARACTER_LIMIT = 150;

  // Check if text needs to be truncated
  const isLongIntention = intention && intention.length > CHARACTER_LIMIT;
  const isLongQuestion = intentionQuestion && intentionQuestion.length > CHARACTER_LIMIT;

  // Get displayed text based on expanded state
  const getDisplayedText = (text: string, isExpanded: boolean) => {
    if (!text || text.length <= CHARACTER_LIMIT || isExpanded) return text;
    return text.substring(0, CHARACTER_LIMIT) + "...";
  };

  if (!intention) return null;
  
  return (
    <>
      <div className={classes.content}>
        <div className={`${classes.header} text-xl font-semibold`}>
          <p className="w-36 text-ellipsis overflow-hidden">{userName}</p>
        </div>
        <div className={classes.body}>
          {randomize && intentionQuestion && (
            <>
              <div className="mb-4">
                <span className="font-semibold">Q : </span>
                <span>
                  {getDisplayedText(intentionQuestion, questionExpanded)}
                  {isLongQuestion && (
                    <button
                      onClick={() => setQuestionExpanded(!questionExpanded)}
                      className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
                    >
                      {questionExpanded ? "Read less" : "Read more..."}
                    </button>
                  )}
                </span>
              </div>
              <div>
                <span className="font-semibold">A : </span>
                <span>
                  {getDisplayedText(intention, intentionExpanded)}
                  {isLongIntention && (
                    <button
                      onClick={() => setIntentionExpanded(!intentionExpanded)}
                      className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
                    >
                      {intentionExpanded ? "Read less" : "Read more..."}
                    </button>
                  )}
                </span>
              </div>
            </>
          )}
          {!randomize && (
            <div>
              {getDisplayedText(intention, intentionExpanded)}
              {isLongIntention && (
                <button
                  onClick={() => setIntentionExpanded(!intentionExpanded)}
                  className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
                >
                  {intentionExpanded ? "Read less" : "Read more..."}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EntryCards;
