import { Feedback } from "../../types";
// import feedbackClasses from "../../FeedbackData.module.css";
import { IconComponents } from "./IconComponents";
import { formatDate } from "../../utils/formatters";

interface FeedbackCardProps {
  feedback: Feedback;
  onView: (feedback: Feedback) => void;
  onDelete: (feedback: Feedback) => void;
}

const FeedbackCard = ({ feedback, onView, onDelete }: FeedbackCardProps) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 hover:border-[#EB8181] transition-colors">
      <div className="space-y-3">
        <IconComponents.Organisation text={feedback.organisation} />
        <IconComponents.Feedback text={feedback.feedback} />
        <IconComponents.Rating text={feedback.rating.toString()} />
        <div className="flex items-center gap-2 text-sm text-gray-500">
          <IconComponents.Date text={formatDate(feedback.timestamp)} />
          <span>•</span>
          <IconComponents.User text={feedback.userName || "Anonymous"} />
        </div>
      </div>

      <div className="flex justify-end gap-3 mt-4 pt-4 border-t border-gray-100">
        <button
          onClick={() => onView(feedback)}
          className="px-4 py-2 text-sm font-medium text-[#EB8181] bg-[#EB8181]/10 rounded-md hover:bg-[#EB8181]/20 transition-colors"
        >
          VIEW DETAILS
        </button>
        <button
          onClick={() => onDelete(feedback)}
          className="p-2 text-gray-400 hover:text-red-500 transition-colors"
        >
          <span className="text-xl transform scale-125">−</span>
        </button>
      </div>
    </div>
  );
};

export default FeedbackCard;
