/**
 * Utility functions for working with IndexedDB to store favorites
 */

const DB_NAME = 'GrowthCirclesDB';
const STORE_NAME = 'favorites';
const DB_VERSION = 1;

/**
 * Initialize the IndexedDB database
 */
export const initDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      reject('Error opening IndexedDB');
    };

    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      
      // Create an object store for favorites if it doesn't exist
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    };
  });
};

/**
 * Add an organization to favorites
 */
export const addToFavorites = async (orgId: string, orgName: string): Promise<void> => {
  try {
    const db = await initDB();
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    
    const favorite = {
      id: orgId,
      name: orgName,
      timestamp: Date.now()
    };
    
    store.put(favorite);
    
    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => {
        resolve();
      };
      
      transaction.onerror = () => {
        reject('Error adding to favorites');
      };
    });
  } catch (error) {
    console.error('Error in addToFavorites:', error);
    throw error;
  }
};

/**
 * Remove an organization from favorites
 */
export const removeFromFavorites = async (orgId: string): Promise<void> => {
  try {
    const db = await initDB();
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    
    store.delete(orgId);
    
    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => {
        resolve();
      };
      
      transaction.onerror = () => {
        reject('Error removing from favorites');
      };
    });
  } catch (error) {
    console.error('Error in removeFromFavorites:', error);
    throw error;
  }
};

/**
 * Check if an organization is in favorites
 */
export const isInFavorites = async (orgId: string): Promise<boolean> => {
  try {
    const db = await initDB();
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    
    const request = store.get(orgId);
    
    return new Promise((resolve) => {
      request.onsuccess = () => {
        resolve(!!request.result);
      };
      
      request.onerror = () => {
        resolve(false);
      };
    });
  } catch (error) {
    console.error('Error in isInFavorites:', error);
    return false;
  }
};

/**
 * Get all favorite organizations
 */
export const getAllFavorites = async (): Promise<string[]> => {
  try {
    const db = await initDB();
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    
    const request = store.getAll();
    
    return new Promise((resolve) => {
      request.onsuccess = () => {
        resolve(request.result.map(item => item.id));
      };
      
      request.onerror = () => {
        resolve([]);
      };
    });
  } catch (error) {
    console.error('Error in getAllFavorites:', error);
    return [];
  }
}; 