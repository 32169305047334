import  { useState } from "react";

interface Props {
  text: string;
  className?: string;
}

export default function Instruction({ text = "", className }: Props) {
  const [expanded, setExpanded] = useState(false);
  const [expandedSteps, setExpandedSteps] = useState<Record<number, boolean>>({});
  
  // Character limit before showing "Read more..."
  const CHARACTER_LIMIT = 150;
  
  // Check if text needs to be truncated
  const isLongText = text.length > CHARACTER_LIMIT;
  
  // Get the displayed text based on expanded state
  const getDisplayedText = (text: string) => {
    if (!isLongText || expanded) return text;
    return text.substring(0, CHARACTER_LIMIT) + "...";
  };

  // Get displayed text for a step based on its expanded state
  const getStepDisplayedText = (text: string, index: number) => {
    const isStepLong = text.length > CHARACTER_LIMIT;
    if (!isStepLong || expandedSteps[index]) return text;
    return text.substring(0, CHARACTER_LIMIT) + "...";
  };

  // Toggle expanded state for a specific step
  const toggleStepExpanded = (index: number) => {
    setExpandedSteps(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // Match and split text by numbers followed by a period (e.g., "1.", "2.", etc.)
  const steps = text
    .match(/\d+\.\s*([^0-9]+)/g)
    ?.map((step) => step.replace(/^\d+\.\s*/, "").trim()) || [text];

  return (
    <div className={"m-4 " + className}>
      {steps.length > 1 ? (
        <ol className="list-decimal pl-6">
          {steps.map((step, index) => {
            const isStepLong = step.length > CHARACTER_LIMIT;
            return (
              <li key={index} className="mb-2 font-semibold text-left">
                {getStepDisplayedText(step, index)}
                {isStepLong && (
                  <button 
                    onClick={(e) => {
                      e.preventDefault();
                      toggleStepExpanded(index);
                    }}
                    className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
                  >
                    {expandedSteps[index] ? "Read less" : "Read more..."}
                  </button>
                )}
              </li>
            );
          })}
        </ol>
      ) : (
        <p className="font-semibold">
          {getDisplayedText(text)}
          {isLongText && (
            <button 
              onClick={() => setExpanded(!expanded)}
              className="ml-1 text-[#EB8181] text-sm font-medium hover:underline focus:outline-none"
            >
              {expanded ? "Read less" : "Read more..."}
            </button>
          )}
        </p>
      )}
    </div>
  );
}
