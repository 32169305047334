import { FaExternalLinkAlt, FaLink } from "react-icons/fa";

interface DefaultResourceCardProps {
  resource: {
    url: string;
    title: string;
  };
}

const DefaultResourceCard = ({ resource }: DefaultResourceCardProps) => (
  <div className="group bg-white rounded-xl border border-gray-200 hover:border-gray-300 transition-all duration-300 hover:shadow-lg overflow-hidden">
    <div className="p-5">
      <div className="flex items-start gap-3">
        <div className="flex-shrink-0 p-2.5 rounded-lg bg-gray-100 group-hover:bg-gray-200 transition-colors">
          <FaLink className="text-gray-500 group-hover:text-gray-700 transition-colors" size={20} />
        </div>
        <div className="flex-1 min-w-0">
          <h3 className="font-semibold text-gray-700 group-hover:text-gray-900 transition-colors">
            {resource.title}
          </h3>
          <a
            href={resource.url}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2 flex items-center gap-2 text-sm text-gray-500 group-hover:text-gray-700 transition-colors"
          >
            <span className="truncate">{resource.url}</span>
            <FaExternalLinkAlt size={12} className="flex-shrink-0" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default DefaultResourceCard;